import { useEffect, useState } from "react";
import { Card, Stack, styled, useTheme } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { alpha, Box } from "@mui/system";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import FlexBetween from "components/flexbox/FlexBetween";
import PublicIcon from "@mui/icons-material/Public";
import { H5, H6, Tiny } from "components/Typography";
import BriefcaseOutlined from "icons/BriefcaseOutlined";
import DateRange from "icons/DateRange";
import Education from "icons/Education";
import EmailOutlined from "icons/EmailOutlined";
import Globe from "icons/Globe";
import UserOutlined from "icons/UserOutlined";
import EditButton from "../EditButton";
import { Paragraph, Small, Span } from "components/Typography";
import GoogleIcon from "@mui/icons-material/Google";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import AddEmployeeModal from "page-sections/data-table/dataTableV2/AddEmployeeModal";
import PasswordButton from "../PasswordButton";
import PasswordModal from "page-sections/data-table/PasswordModal";
import PlanButton from "./PlanButton";
import PlanModal from "page-sections/data-table/PlanModal";
import ATooltip from "components/shared/ATooltip";
import FlexBox from "components/flexbox/FlexBox";

const GooglePicture = styled("img")`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;
const AdditionalDetails = ({
  showUser,
  openModal,
  setOpenModal,
  active,
  openEditModal,
  setOpenEditModal,
}) => {
  // const [openEditModal, setOpenEditModal] = useState(false);
  const [openPlanModal, setOpenPlanModal] = useState(false);

  // Get data from usercontext api
  const { userdata, getUser, getUserTeamMember } = useContext(UserContext);

  const theme = useTheme();

  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <FlexBetween>
        <H5>Additional Details</H5>
        <FlexBox>
          <ATooltip content="Change Password">
            <PasswordButton onClick={() => setOpenEditModal(true)} />
          </ATooltip>

          {showUser && (
            <PasswordModal
              open={openEditModal}
              onClose={() => setOpenEditModal(false)}
              data={showUser}
            />
          )}

          <ATooltip content="Edit User">
            <EditButton onClick={() => setOpenModal(true)} />
          </ATooltip>

          {showUser && (
            <AddEmployeeModal
              onSuccess={getUser}
              data={showUser}
              edit={true}
              discord={true}
              open={openModal}
              onClose={() => setOpenModal(false)}
              getUserTeamMember={() => getUserTeamMember(userdata._id, 0)}
            />
          )}

          {/* <ATooltip content="Change Plan">
            <PlanButton onClick={() => setOpenPlanModal(true)} />
          </ATooltip> */}

          <PlanModal
            open={openPlanModal}
            onClose={() => setOpenPlanModal(false)}
            data={showUser}
            onSuccess={getUser}
          />
        </FlexBox>
      </FlexBetween>

      <Stack mt={3} spacing={2}>
        <ListItem
          title={showUser?.email ? "Email" : "Mobile"}
          subTitle={showUser?.email ? showUser?.email : showUser?.mobile_number}
          Icon={EmailOutlined}
          color={theme.palette.primary.main}
        />

        {/* <ListItem
          title="Phone"
          subTitle={userdata?.phone_number}
          Icon={Globe}
          color={theme.palette.info.main}
        /> */}

        <ListItem
          title="P2P Account Status"
          subTitle={
            <Small
              sx={{
                width: 100,
                fontSize: 10,
                color: "#fff",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor: showUser?.account_connected
                  ? "success.main"
                  : "error.main",
              }}
            >
              {showUser?.account_connected ? "Connected" : "Not Connected"}
            </Small>
          }
          Icon={UserOutlined}
          color={theme.palette.warning.main}
        />

        <ListItem
          title="Trading Status"
          subTitle={
            <Small
              sx={{
                width: 100,
                fontSize: 10,
                color: "#fff",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor: showUser?.trading_active
                  ? "success.main"
                  : "error.main",
              }}
            >
              {showUser?.trading_active ? "Active" : "Not Active"}
            </Small>
          }
          Icon={DateRange}
          color={theme.palette.success.main}
        />

        <ListItem
          title="Country"
          subTitle={showUser?.country}
          Icon={PublicIcon}
          color={theme.palette.primary.main}
        />

        <ListItem
          title="Time Zone"
          subTitle={showUser?.timezone}
          Icon={WatchLaterIcon}
          color={theme.palette.secondary.main}
        />
        <ListItem
          title="Join Date"
          subTitle={<FormattedDateTime date={showUser?.createdAt} />}
          Icon={WatchLaterIcon}
          color={theme.palette.secondary.main}
        />
        {/* {showUser?.discord && (
          <ListItem
            title="Discord"
            subTitle={showUser?.discord}
            Icon={AccountBoxIcon}
            color={theme.palette.primary.main}
          />
        )} */}

        <ListItem
          title="Discord"
          subTitle={showUser?.discord?.username}
          Img={
            !showUser?.discord?.avatar
              ? "/media/avatars/blank.png"
              : `https://cdn.discordapp.com/avatars/${showUser?.discord?.id}/${showUser?.discord?.avatar}.png`
          }
          color={theme.palette.primary.main}
        />
        {showUser?.isGoogleSignup && (
          <ListItem
            title="Google"
            subTitle={showUser?.googleId.email}
            Img={
              !showUser?.googleId?.picture
                ? "/media/avatars/blank.png"
                : showUser?.googleId?.picture
            }
            color={theme.palette.error.main}
          />
        )}
      </Stack>
    </Card>
  );
};

export default AdditionalDetails; // ----------------------------------------------------

// ----------------------------------------------------
function ListItem({ title, subTitle, Icon, color, Img }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 30,
          height: 30,
          borderRadius: "4px",
          backgroundColor: alpha(color, 0.2),
        }}
      >
        {Icon && (
          <Icon
            sx={{
              color,
            }}
          />
        )}
        {Img && <img src={Img} style={{ height: "30px", width: "30px" }} />}
      </Stack>
      <Box>
        <Tiny fontWeight={500}>{title}</Tiny>
        <H6 fontSize={12}>{subTitle}</H6>
      </Box>
    </Stack>
  );
}
