import axios from "../utils/axios";

const TradeServices = {};

TradeServices.getTrades = function (
  user_id,
  account_id,
  type,
  page,
  limit,
  search,
  status
) {
  return axios({
    url: `/trades/?user_id=${user_id}&account_id=${account_id}&type=${type}&page=${page}&limit=${limit}&search=${search}&status=${status}`,
    method: "GET",
  });
};
TradeServices.getTrade = function (trade_hash, user_id) {
  return axios({
    url: `/trades/${trade_hash}?user_id=${user_id}`,
    method: "GET",
  });
};

TradeServices.getAllTrade = function (page, limit, status, search) {
  return axios({
    url: `/trades/history?page=${page}&limit=${limit}&status=${status}&search=${search}`,
    method: "GET",
  });
};

TradeServices.getActiveTrades = function (page, limit, search) {
  return axios({
    url: `/trades/active-trades?page=${page}&limit=${limit}&search=${search}`,
    method: "GET",
  });
};
TradeServices.getVendorDetails = (account_id, buyer_id) => {
  return axios({
    url: `/trades/partner-stats/${account_id}/${buyer_id}`,
    method: "GET",
  });
};

export default TradeServices;
