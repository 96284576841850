import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5, Paragraph } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import InvoiceListTable from "page-sections/activity-log/index";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { WEBHOOK_API_URL } from "config";
import WebhookService from "services/WebhookService";
import { io } from "socket.io-client";
import ActivityLogServices from "services/ActivityLogService";
import { LoadingButton } from "@mui/lab";
import HiringRequestListTable from "page-sections/hiring-request/index";
import HiringRequestService from "services/HireRequestService";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { UserContextData } from "App";

const socket = io(WEBHOOK_API_URL);

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "50%",
  },
}));

const HireRequest = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const { hireRequest } = useContext(UserContextData);

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState(null);

  const [option, setOption] = React.useState("");
  console.log("hireRequest", hireRequest);
  // get hiring request
  const getHiringRequest = async (
    page = 1,
    limit = 50,
    status = "",
    search = ""
  ) => {
    setisLoading(true);
    const resp = await HiringRequestService.getHireRequest(
      page,
      limit,
      status,
      search
    );
    setData(resp);
    setPage(page);
    // setLimit(50);
    setisLoading(false);
    console.log("Hiring request", resp);
  };

  useEffect(() => {
    getHiringRequest(1, 50, option, searchValue);
  }, []);

  useEffect(() => {
    console.log("run use effect by socket");
    console.log("hireRequest", hireRequest);
    let _data = { ...data };
    _data.records = hireRequest;
    setData(_data);
  }, [hireRequest]);

  const reload = () => {
    setSearchValue("");
    setOption("");
    getHiringRequest();
  };

  const handlePageChange = (page) => {
    // setPage(page);
    getHiringRequest(page, limit, "all", searchValue);
  };

  const handleLimitChange = (event) => {
    // setPage(1);
    setLimit(event.target.value);
    getHiringRequest(1, event.target.value, "", searchValue);
  };

  // const handleChange = (event) => {
  //   const selectedOption = event.target.value;
  //   setOption(selectedOption);
  //   getHiringRequest(1, limit, selectedOption, searchValue);
  // };
  const handleChange = (event) => {
    setOption(event.target.value);
    setPage(1);
    getHiringRequest(1, 50, event.target.value, searchValue);
  };

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
    getHiringRequest(1, 50, option, searchValue);
  };

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Hiring Request")}</H5>
        </Stack>

        <StyledStack alignItems="center" direction="row" gap={2}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              sx={{
                "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px !important", // Remove padding from the Select component
                  },
              }}
              value={option}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <Paragraph>All</Paragraph>
              </MenuItem>
              <MenuItem value={"succesfull"}>
                {" "}
                <Paragraph>Successful</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"active"}>
                {" "}
                <Paragraph>Active</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"schedule"}>
                {" "}
                <Paragraph>Schedule</Paragraph>{" "}
              </MenuItem>
            </Select>
          </FormControl>
          <SearchInput
            disable_border="true"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && (
          // console.log("DATA", data),
          <HiringRequestListTable
            {...data}
            onPageChange={handlePageChange}
            currentPage={page}
            handleLimitChange={handleLimitChange}
            limit={limit}
            getHiringRequest={() => getHiringRequest(1, 50, "all", "")}
          />
        )
      )}
    </Box>
  );
};

export default HireRequest;
