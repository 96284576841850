import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Stack,
  styled,
  Table,
  TableRow,
  useTheme,
} from "@mui/material";
import { Button } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import FlexBetween from "components/flexbox/FlexBetween";
import { H5 } from "components/Typography";
import IconWrapper from "components/IconWrapper";
import GroupSenior from "icons/GroupSenior";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import AppPagination from "components/AppPagination";
import Scrollbar from "components/ScrollBar";
import BlankCheckBoxIcon from "icons/BlankCheckBoxIcon";
import CheckBoxIcon from "icons/CheckBoxIcon";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";

import HeadingArea from "page-sections/user-list/heading-area";
import { useCallback, useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import OfferService from "services/OffersService";
import { useNavigate } from "react-router-dom";
import PaginanationAndLimit from "components/shared/PaginanationAndLimit";

import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import {
  useAsyncDebounce,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { getUsers } from "redux/actions/user";
import NoResultFound from "components/shared/NoResultFound";
import { arrayIsEmpty } from "utils/utils";
import NotificationService from "services/NotificationService";
import columnShape from "./columnShape";
import SearchInput from "components/input-fields/SearchInput";
import { debounce } from "lodash";
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 13,
  fontWeight: 500,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const SelectCheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    if (resolvedRef) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);
  return (
    <Checkbox
      {...rest}
      disableRipple
      ref={resolvedRef}
      icon={<BlankCheckBoxIcon fontSize="small" color="disabled" />}
      checkedIcon={<CheckBoxIcon fontSize="small" color="primary" />}
    />
  );
});
const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const Notification = (props) => {
  const [value, setValue] = useState("");
  const theme = useTheme();
  const columns = useMemo(() => columnShape, []);
  const [pageNum, setPageNum] = useState(1);
  const [limit, setLimit] = useState(50);
  const { userdata, notification, setNotification } = useContext(UserContext);
  const [isLoading, setisLoading] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state,
    setGlobalFilter,
    selectedFlatRows,
    currentPage,
  } = useTable(
    {
      columns,
      data: notification && notification.data,
      pageCount: notification && notification.num_pages,
      manualPagination: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: "selection",
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <SelectCheckBox {...getToggleAllRowsSelectedProps()} />
    //       ),
    //       Cell: ({ row }) => (
    //         <SelectCheckBox {...row.getToggleRowSelectedProps()} />
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  ); // handle change pagination

  const onPageChange = (page, limit) => {
    getUserNotification(userdata._id, searchValue, page, limit);
  };

  const handleChange = (_, currentPageNo) => onPageChange(currentPageNo);

  const changeTab = (_, newValue) => setValue(newValue); // search value state

  const [searchValue, setSearchValue] = useState(state.globalFilter);
  // const handleSearch = useAsyncDebounce(
  //   (value) => setGlobalFilter(value || undefined),
  //   200
  // );

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      getUserNotification(userdata._id, searchValue, 1, limit);
    }, 500),
    [limit]
  );

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
    setPageNum(1);
    debouncedSearch(searchValue);
  };
  //   calling api ================
  const getUserNotification = async (id, search = "", page = 1, limit = 50) => {
    setisLoading(true);
    const response = await NotificationService.getUsernotification(
      id,
      search,
      page,
      limit
    );
    if (response.status) {
      setNotification(response);
      setPageNum(page);
      setisLoading(false);
    } else {
      setisLoading(true);
    }
    window.scrollTo(0, 100);
  };

  useEffect(() => {
    getUserNotification(userdata._id, searchValue, pageNum, limit);
  }, [pageNum, limit]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPageNum(1);
  };

  const reload = () => {
    setSearchValue("");
    getUserNotification(userdata._id);
  };

  const navigate = useNavigate();

  console.log("notification", notification);
  return (
    <Box pt={2} pb={4}>
      <Card
        sx={{
          py: 2,
        }}
      >
        <FlexBetween flexWrap="wrap" gap={1} mb={2}>
          <Box px={3}>
            {/* <FlexBox alignItems="center">
                <IconWrapper>
                <GroupSenior
                    sx={{
                    color: "primary.main",
                    }}
                />
                </IconWrapper>
                <H5>{("Trades")}</H5>
            </FlexBox> */}
            {/* <HeadingArea value={value} changeTab={changeTab} /> */}

            <SearchInput
              placeholder="Search"
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>

          <StyledStack alignItems="center" direction="row" gap={2} px={2}>
            <LoadingButton
              onClick={() => reload()}
              variant="contained"
              loading={isLoading}
              sx={{
                flexShrink: 0,
              }}
            >
              Reload
            </LoadingButton>
          </StyledStack>
        </FlexBetween>

        <Scrollbar autoHide={false}>
          <Table
            {...getTableProps()}
            sx={{
              minWidth: 900,
            }}
          >
            <TableHead
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              {headerGroups.map((headerGroup, index) => (
                <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <HeadTableCell
                      key={index}
                      // {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </HeadTableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {isLoading ? (
                <StyledTableCell colSpan={8} align="center">
                  <CircularProgress />
                </StyledTableCell>
              ) : arrayIsEmpty(notification.data) ? (
                <StyledTableCell colSpan={8} align="center">
                  <NoResultFound />
                </StyledTableCell>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      key={index}
                      //   onClick={() => handleViewModal(row.original)}
                      // style={{
                      //   cursor: "pointer",
                      // }}
                    >
                      {notification.length === 0 ? (
                        <BodyTableCell
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <NoResultFound />
                        </BodyTableCell>
                      ) : (
                        row.cells.map((cell, index) => (
                          <BodyTableCell key={index} {...cell.getCellProps()}>
                            {cell.render("Cell", { onClick: props.onSelect })}
                          </BodyTableCell>
                        ))
                      )}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Scrollbar>
        {/* Model Open And Close */}
        {/* {viewOffer && (
          <ViewOfferModal
            data={viewOffer}
            open={openModal}
            onClose={() => setOpenModal(false)}
          />
        )} */}

        {/* <Stack alignItems="center" marginY={4}> */}
        {notification && notification.data.length > 0 && (
          <PaginanationAndLimit
            tableData={notification.data || []}
            handleChange={(event, page) => setPageNum(page)}
            count={notification.num_pages || 0}
            currentPage={pageNum}
            handleLimitChange={handleLimitChange}
            limit={limit}
          />
        )}
        {/* </Stack> */}
      </Card>
    </Box>
  );
};
export default Notification;
