import { Box } from "@mui/system";
import { useState, useEffect, createContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Layout from "page-sections/profiles/buyer/Layout";
import Overview from "page-sections/profiles/buyer/overview/Overview";
import buyerService from "services/buyer";

const ViewBuyer = () => {
  const navigate = useNavigate();
  const [buyer, setBuyer] = useState(null);

  const { id } = useParams();
  console.log("params", id);

  const getBuyer = async (id) => {
    const resp = await buyerService.getBuyer(id);
    console.log("resp ss", resp);
    setBuyer(resp.data);
  };
  useEffect(() => {
    console.log("yuuseeffect run");
    getBuyer(id);
  }, []);

  console.log("buyer", buyer);

  return (
    <Box pt={2} pb={4}>
      {buyer && <Layout buyer={buyer} />}
      {buyer && <Overview buyer={buyer} buyer_id={id} />}
    </Box>
  );
};

export default ViewBuyer;
