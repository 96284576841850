import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5, Paragraph } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import InvoiceListTable from "page-sections/invoice/list";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { useContext } from "react";
import { LoadingButton } from "@mui/lab";
import WalletTable from "./WalletTable";
import PaxfulAccountsService from "services/PaxfulAccountsService";
import { UserContext } from "page-sections/user-list/ViewUser";
import { getTotalBalance } from "utils/currencies";
import Platform from "components/shared/Platform";
import FlexBox from "components/flexbox/FlexBox";
import { AccountSelect } from "../offer/Offers";
import cryptoAssets from "data/supportedAssets";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const Wallet2 = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  // ================================
  // wallet Transactions
  // =================================
  const { userdata } = useContext(UserContext);
  const [isLoading, setisLoading] = useState(false);
  const [accounts, setAccounts] = useState(null);
  const [data, setData] = useState([]);
  const [account, setAccount] = useState("active");
  const [userAccounts, setUserAccounts] = useState([]);
  const [option, setOption] = useState("all");

  //   // const [transactions, setTrasnactions] = useState([]);
  //   const [num_page, setnum_page] = useState(0);
  //   const [pg, setpg] = React.useState(0);
  //   const [limit, setLimit] = React.useState(10);

  // getUser's Address
  const getUserAccountAddress = async (
    id,
    account_id = "all",
    type = "active"
  ) => {
    setisLoading(true);
    const accounts = await PaxfulAccountsService.getUserAccountAddress(
      id,
      account_id,
      type
    );

    // accounts?.records?.forEach((account) => {
    //   console.log("account564564654", account);
    //   const convertedData = convertData(account);
    //   setData((pv) => [...pv, ...convertedData]);
    // });

    setAccounts(accounts.records);
    setisLoading(false);
  };
  // ----------------------------------------
  // get User account
  const getUserPaxfulAccounts = async (id, account_id, type) => {
    // setisLoading(true);
    const accounts = await PaxfulAccountsService.getUserPaxfulAccounts(
      id,
      "",
      ""
      // page,
      // limit
    );

    setUserAccounts(accounts.records);
    // setisLoading(false);
  };

  useEffect(() => {
    getUserAccountAddress(userdata._id);
    getUserPaxfulAccounts(userdata._id);
  }, []);

  // =========================================

  const reload = () => {
    setAccount("active");
    setOption("all");

    getUserAccountAddress(userdata._id);
  };

  let netwrokTags = {
    Ton: "TON",
    Ethereum: "ERC-20",
    Tron: "TRC-20",
    BSC: "BEP-20",
    "BNB Smart Chain": "BEP-20",
    Solana: "SOL",
  };

  const getNetworkTag = (network, platform, index) => {
    console.log("NETWORK", network, platform, index);
    console.log("index", index);
    if (platform == "paxful") {
      switch (index) {
        case 0:
          return netwrokTags.Tron;
        case 1:
          return netwrokTags.Ethereum;
      }
    } else if (platform == "noones") {
      return netwrokTags[network];
      switch (index) {
        case 0:
          return netwrokTags.Ton;
        case 1:
          return netwrokTags.BSC;
        case 2:
          return netwrokTags.Ethereum;
        case 3:
          return netwrokTags.Tron;
      }
    } else {
      if (!network) return null;
      return netwrokTags[network];
    }
  };

  function convertData(account) {
    const convertedData = [];
    console.log("account", account);

    // Extract BTC address information
    account.btc_addresses.forEach((address) => {
      convertedData.push({
        account_username: account.paxful_username,
        account_profile: account.paxful_avatar,
        account_platform: account.platform,
        crypto: "BTC",
        crypto_address: address.address,
        crypto_balance: getTotalBalance([account], "BTC"),
        account: account,
      });
    });

    // Extract ETH address information
    account.eth_addresses.forEach((address, index) => {
      convertedData.push({
        account_username: account.paxful_username,
        account_profile: account.paxful_avatar,
        account_platform: account.platform,
        crypto: "ETH",
        crypto_address: address.address,
        crypto_balance: getTotalBalance([account], "ETH"),
        account: account,
      });
    });

    // Extract USDT address information
    account.usdt_addresses.forEach((address, index) => {
      convertedData.push({
        account_username: account.paxful_username,
        account_profile: account.paxful_avatar,
        account_platform: account.platform,
        crypto: "USDT",
        crypto_address: address.address,
        crypto_balance: getTotalBalance([account], "USDT"),
        network: getNetworkTag(address.network, account.platform, index),
        account: account,
        address_memo: address?.address_memo,
      });
    });

    // Extract USDC address information
    account.usdc_addresses.forEach((address, index) => {
      convertedData.push({
        account_username: account.paxful_username,
        account_profile: account.paxful_avatar,
        account_platform: account.platform,
        crypto: "USDC",
        crypto_address: address.address,
        crypto_balance: getTotalBalance([account], "USDC"),
        network: getNetworkTag(address.network, account.platform, 2),
        account: account,
      });
    });

    account?.platform === "noones" &&
      account.sol_addresses.forEach((address, index) => {
        convertedData.push({
          account_username: account.paxful_username,
          account_profile: account.paxful_avatar,
          account_platform: account.platform,
          crypto: "SOL",
          crypto_address: address.address,
          crypto_balance: getTotalBalance([account], "SOL"),
          account: account,
        });
      });

    account?.platform === "noones" &&
      account.ton_addresses.forEach((address, index) => {
        convertedData.push({
          account_username: account.paxful_username,
          account_profile: account.paxful_avatar,
          account_platform: account.platform,
          crypto: "TON",
          crypto_address: address.address,
          crypto_balance: getTotalBalance([account], "TON"),
          account: account,
          address_memo: address.address_memo,
        });
      });
    account?.platform === "noones" &&
      account.xmr_addresses.forEach((address, index) => {
        convertedData.push({
          account_username: account.paxful_username,
          account_profile: account.paxful_avatar,
          account_platform: account.platform,
          crypto: "XMR",
          crypto_address: address.address,
          crypto_balance: getTotalBalance([account], "XMR"),
          account: account,
          address_memo: address.address_memo,
        });
      });

    return convertedData;
  }

  useEffect(() => {
    // Usage example
    // setisLoading(true);

    accounts &&
      accounts.forEach((account) => {
        const convertedData = convertData(account);
        setData((pv) => [...pv, ...convertedData]);
        // setData(convertedData);
      });
    // setisLoading(false);
  }, [accounts]);

  const selectHandleChange = (event) => {
    setOption(event.target.value);
    // call api for get account again
    setData([]);
    getUserAccountAddress(userdata._id, event.target.value);
  };

  const handleTabChange = (event) => {
    setAccount(event.target.value);
    // call api for get account again
    setData([]);
    getUserAccountAddress(userdata._id, option, event.target.value);
  };

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("P2P Wallet")}</H5>
        </Stack>
        <StyledStack alignItems="center" direction="row" gap={2}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              sx={{
                "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px !important", // Remove padding from the Select component
                  },
              }}
              onChange={(e) => handleTabChange(e)}
              value={account}
              disabled={option != "all"}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <Paragraph>All</Paragraph>
              </MenuItem>
              <MenuItem value={"active"}>
                {" "}
                <Paragraph>Active</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"delete"}>
                {" "}
                <Paragraph>Deleted</Paragraph>{" "}
              </MenuItem>
            </Select>
          </FormControl>

          <AccountSelect
            option={option}
            selectHandleChange={selectHandleChange}
            userAccounts={userAccounts}
          />
          <LoadingButton
            onClick={() => reload()}
            variant="contained"
            loading={isLoading}
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>
      {/* {data && <WalletTable data={data} />} */}
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && <WalletTable data={data} />
      )}
    </Box>
  );
};

export default Wallet2;
