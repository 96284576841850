import reactStringReplace from "react-string-replace";
import PaxfulProfilePic from "./PaxfulProfilePic";
import { Box } from "@mui/material";
import { Small, Span } from "components/Typography";
import Platform from "./Platform";
import cryptoAssets from "data/supportedAssets";

export const replaceNotificationTextTags = (
  text,
  trade,
  buyer,
  transaction
) => {
  //-- replace [TRADE_HASH] with trade link
  let message;

  if (trade) {
    message = reactStringReplace(
      text,
      "[TRADE_HASH]",
      () => trade?.paxful_trade_hash
    );
  } else if (transaction) {
    console.log("transaction", transaction);
    let asset = cryptoAssets.find((o) => o.assetID === transaction?.assetId);

    message = reactStringReplace(
      text,
      "[AMOUNT]",
      () => transaction.crypto_amount
    );
    message = reactStringReplace(message, "[CODE]", () => asset?.code);

    message = reactStringReplace(message, "[CRYPTO]", () => asset?.title);
  }

  message = reactStringReplace(message, "[BUYER]", () => (
    <>
      <Box
        mr={0.5}
        sx={{
          display: "inline-block",
          alignItem: "center",
          position: "relative",
        }}
      >
        <PaxfulProfilePic
          size={15}
          account={buyer.profile}
          buyer={true}
          className="m-0"
        />
        <Box
          sx={{
            position: "absolute",
            top: -4,
            left: 10,
          }}
        >
          <Platform
            platform={buyer.platform}
            size={15}
            className="h-15px platform-icon"
          />
        </Box>
      </Box>{" "}
      <span className="ms-0">{buyer.username}</span>
    </>
  ));

  message = reactStringReplace(message, "[CRYPTO]", () => {
    return (
      <>
        <div style={{ display: "inline" }}>
          <img
            src={`/media/svg/coins/${trade?.trade?.crypto_currency_code.toLowerCase()}.svg`}
            style={{ width: "14px", height: "14px" }}
            className="me-1"
          />{" "}
          {trade?.trade?.crypto_currency_code}{" "}
        </div>
      </>
    );
  });

  message = reactStringReplace(message, "[PAXFUL_LOGO]", () => {
    return (
      <Span mr={0.5} mt={0.5}>
        <img
          src={`/media/logos/${buyer.platform}-icon.png`}
          style={{ width: "20px", height: "20px" }}
        />
      </Span>
    );
  });
  message = reactStringReplace(message, "[NOONES_LOGO] ", () => {
    return (
      <Span mr={0.5} mt={0.5}>
        <img
          src={`/media/logos/${buyer.platform}-icon.png`}
          style={{ width: "20px", height: "20px" }}
        />
      </Span>
    );
  });
  return message;
};
