import { Card, CardContent, Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import OutsideClickHandler from "react-outside-click-handler";
import Summery from "page-sections/profiles/profile/overview/Summery";
import Skills from "page-sections/profiles/profile/overview/Skills";
import Hobbies from "page-sections/profiles/profile/overview/Hobbies";
import Post from "page-sections/profiles/profile/overview/Post";
import Portfolio from "page-sections/profiles/profile/overview/Portfolio";
import MyConnections from "page-sections/profiles/profile/overview/MyConnections";
import Trade from "./Trade";
import BuyerInformation from "./BuyerInformation";

const Overview = ({ buyer, buyer_id }) => {
  console.log("Overview buyer", buyer);
  return (
    <Box mt={3}>
      <Grid container spacing={2}>
        <Grid item lg={8} md={8} xs={12}>
          <Stack spacing={2}>
            <Card>
              <CardContent>
                <Trade buyer_id={buyer_id} />
              </CardContent>
            </Card>
          </Stack>
        </Grid>
        {buyer && (
          <Grid item lg={4} md={4} xs={12}>
            <Stack spacing={3}>
              <BuyerInformation buyer={buyer} />
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Overview;
