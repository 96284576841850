import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { H3, H5, H6, Paragraph } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import * as Yup from "yup"; // component props interface
import { useFormik } from "formik";
import AppTextField from "components/input-fields/AppTextField";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import USAStates from "data/USAStates";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ConfigurationService from "services/ConfigurationService";
import { makeStyles } from "@mui/styles";
import PaymentFeeStructure from "services/FeeStructure";
import PercentIcon from "@mui/icons-material/Percent";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, city, theme) {
  return {
    fontWeight:
      city.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const commonCSS = {
  minWidth: 120,
  "&:nth-of-type(2)": {
    minWidth: 170,
  },
  "&:nth-of-type(3)": {
    minWidth: 80,
  },
}; // Styled components

const HeadTableCell = styled(TableCell)(() => ({
  fontSize: 12,
  fontWeight: 600,
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 0,
  },
}));
const BodyTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  padding: 0,
  paddingLeft: "1rem",
  paddingTop: "0.7rem",
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 0,
  },
  [theme.breakpoints.down("sm")]: { ...commonCSS },
  [theme.breakpoints.between(960, 1270)]: { ...commonCSS },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const useStyles = makeStyles({
  // table: {
  //   minWidth: 650,
  // },
  tableCell: {
    paddingTop: "20px !important",
    paddingBottom: "20px !important",
    borderBottom: "1px solid rgba(224, 224, 224, 1)", // Customize the border style here
  },
});

function FeeStructure() {
  const classes = useStyles();
  let paymentMethods = [
    // {
    //   payment_method_name: "ALL PAYMENT METHODS",
    //   payment_method_slug: "all-payment-methods",
    //   fee_percentage: "",
    //   platform: "paxful",
    // },
    {
      payment_method_name: "BANK TRANSFER",
      payment_method_slug: "bank-transfers",
      fee_percentage: "",
      platform: "paxful",
    },
    {
      payment_method_name: "ONLINE WALLET",
      payment_method_slug: "online-transfers",
      fee_percentage: "",
      platform: "paxful",
    },
    {
      payment_method_name: "Debit/credit cards",
      payment_method_slug: "debitcredit-cards",
      fee_percentage: "",
      platform: "paxful",
    },
    {
      payment_method_name: "GIFT CARDS",
      payment_method_slug: "gift-cards",
      fee_percentage: "",
      platform: "paxful",
    },
    {
      payment_method_name: "DIGITAL CURRENCY",
      payment_method_slug: "digital-currencies",
      fee_percentage: "",
      platform: "paxful",
    },
    {
      payment_method_name: "CASH PAYMENT",
      payment_method_slug: "cash-deposits",
      fee_percentage: "",
      platform: "paxful",
    },
    {
      payment_method_name: "GOODS & SERVICES",
      payment_method_slug: "goods-and-services",
      fee_percentage: "",
      platform: "paxful",
    },
  ];

  const [paymentMethodGroups, setpaymentMethodGroups] =
    useState(paymentMethods);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  //   For save city
  const [selectPMGs, setSelectPMGs] = React.useState([]);
  const [fee, setFee] = React.useState([]);

  const handleFeeChange = async (e, item) => {
    const newFee = parseFloat(e.target.value);
    console.log("newFee", newFee);

    setpaymentMethodGroups((prevPaymentMethodGroups) => {
      return prevPaymentMethodGroups.map((paymentMethod) => {
        if (paymentMethod.payment_method_slug === item.payment_method_slug) {
          return { ...paymentMethod, fee_percentage: newFee };
        }
        return paymentMethod;
      });
    });
  };

  const SaveFeeStructure = async (data) => {
    await PaymentFeeStructure.SaveFeeStructure(data, "paxful");
  };

  const getFeeStructureData = async (platform) => {
    const resp = await PaymentFeeStructure.getFeeStructure(platform);
    return resp;
  };

  useEffect(async () => {
    const data = await getFeeStructureData("paxful");
    console.log("data", data);
    if (data.status) {
      setpaymentMethodGroups(data.data);
    }
  }, []);

  const handleSubmit = async () => {
    console.log("paymentMethodGroups", paymentMethodGroups);
    SaveFeeStructure(paymentMethodGroups);

    //    call api for save accepted stats and fee
  };

  return (
    <div>
      <Card>
        <CardContent>
          <Box p={4}>
            <H3>Paxful Fee Structure</H3>

            {/* <form onSubmit={handleSubmit}>
              <Grid mt={2} container spacing={2}>
                <Grid item xs={9}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-chip-label">
                      Payment Method Group
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={selectPMGs}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Payment Method Group"
                        />
                      }
                      renderValue={(selected) => {
                        return (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected &&
                              selected.map((value) => (
                                <Chip size="small" key={value} label={value} />
                              ))}
                          </Box>
                        );
                      }}
                      MenuProps={MenuProps}
                    >
                      {paymentMethodGroups.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, selectPMGs, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="outlined-basic"
                    label="Fee Percentage"
                    variant="outlined"
                    type="number"
                    value={fee}
                    onChange={(e) => handleFeeChange(e)}
                    placeholder="Fee Percentage"
                  />
                </Grid>
              </Grid>

              <FlexBox mt={2} sx={{ justifyContent: "end" }}>
                <LoadingButton
                  size="small"
                  onClick={handleSubmit}
                  variant="contained"
                  loading={isLoading}
                >
                  Add More
                </LoadingButton>
              </FlexBox>
            </form> */}
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                >
                  <TableRow>
                    <HeadTableCell>PAYMENT METHOD NAME</HeadTableCell>
                    <HeadTableCell sx={{ textAlign: "center" }}>
                      FEE
                    </HeadTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paymentMethodGroups &&
                    paymentMethodGroups.map((item, index) => {
                      return (
                        <StyledTableRow
                          // sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                          sx={{
                            borderBottom: "1px solid lightgray",
                          }}
                          key={index}
                        >
                          <BodyTableCell className={classes.tableCell}>
                            {item.payment_method_name}
                          </BodyTableCell>
                          <BodyTableCell className={classes.tableCell}>
                            {/* <FeeInput
                              fee={fee}
                              handleFeeChange={handleFeeChange}
                            /> */}

                            <TextField
                              variant="outlined"
                              type="number"
                              value={item.fee_percentage}
                              onChange={(e) => handleFeeChange(e, item)}
                              placeholder="Fee Percentage"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <PercentIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </BodyTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <FlexBox mt={2} sx={{ justifyContent: "end" }}>
            <LoadingButton
              size="small"
              onClick={handleSubmit}
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </FlexBox>
        </CardContent>
      </Card>
    </div>
  );
}

export default FeeStructure;

export function FeeInput({ handleFeeChange, fee }) {
  return (
    <div>
      <TextField
        variant="outlined"
        type="number"
        value={fee}
        onChange={(e) => handleFeeChange(e)}
        placeholder="Fee Percentage"
      />
    </div>
  );
}
