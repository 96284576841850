import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import BlockChainTrasnService from "services/BlockChainTrasnService";
import cryptoAssets from "data/supportedAssets";
import {
  formatCryptoDecimal,
  formatCurrency,
  getCurrencyNameByCode,
} from "utils/currencies";
import { UserContext } from "page-sections/user-list/ViewUser";
import { CardHeader, Icon } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import {
  Grid,
  Typography,
  Divider,
  Link,
  Button,
  IconButton,
  SvgIcon,
  Card,
  CardContent,
} from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import moment from "moment";
import CryptoImg from "components/shared/CryptoImg";
import FlexBox from "components/flexbox/FlexBox";
import CreatedBy from "components/shared/CreatedBy";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import { Small, Span } from "components/Typography";
import { getFormatedFiatAmount } from "utils/crypto";
import AnimationPlayer from "components/shared/AnimationPlayer";
import transactionConfirmedLight from "../../../../assets/animations/transaction-confirmed-light.json";
import transactionConfirmedDark from "../../../../assets/animations/transaction-confirmed-dark.json";
import pendingConfirmationLight from "../../../../assets/animations/pending-confirmation-light.json";
import pendingConfirmationDark from "../../../../assets/animations/pending-confirmation-dark.json";
import Balance from "components/shared/Balance";
import { useLocation } from "react-router-dom";
import BackButton from "../Trades/BackButton";
import { SettingsContext } from "contexts/settingsContext";
import CryptoSymbol from "components/shared/CryptoSymbol";
import UserService from "services/UserService";
import { UserContextData } from "App";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TransactionValue = ({ title, value, titleClass, valueClass, muted }) => (
  <>
    <FlexBox
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        text: "end",
        color: muted ? "text.disabled" : null,
      }}
      className="d-flex justify-content-between align-items-center text-end"
    >
      <Small
        sx={{
          fontWeight: "600",
          minWidth: "150px",
        }}
      >
        {title}
      </Small>
      <Small
        sx={{ wordBreak: "break-word" }} // Added to break words when necessary
      >
        {value}
      </Small>
    </FlexBox>
    <Divider style={{ margin: "10px 0" }} />
  </>
);

TransactionValue.defaultProps = {
  titleClass: "fw-bold text-gray-800",
  valueClass: "fw-semibold text-gray-700",
};

function ViewTransaction() {
  const navigate = useNavigate();
  const { user_id, id } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [asset, setAsset] = useState(null);
  // const { userdata } = useContext(UserContext);

  const { settings } = useContext(SettingsContext);
  const { liveRate } = useContext(UserContextData);

  // Inside your functional component
  const location = useLocation();
  // const userData = location?.state?.additionalData;
  const [userData, setUserData] = useState(null);

  console.log("userData", userData);
  const getSingleBcTrasn = async (id, user_id) => {
    const resp = await BlockChainTrasnService.getSingleBcTranscation(
      id,
      user_id
    );
    if (resp.status) {
      setTransaction(resp.data);
    }
  };

  useEffect(() => {
    getSingleBcTrasn(id, user_id);
  }, []);

  useEffect(() => {
    if (transaction) {
      const asset = cryptoAssets.find(
        ({ assetID }) => assetID == transaction.assetId
      );

      setAsset(asset);
    }
  }, [transaction]);

  const getAssetBalance = () => {
    console.log("userData", userData);
    return userData?.wallet[0].asset_balance[asset.assetID].crypto_balance;
  };

  let live_rate =
    liveRate &&
    userData &&
    liveRate.find(({ base }) => base === userData.currency);

  console.log("liveRate", live_rate);

  const getWithdrawalTxnDescription = ({ status, txnHash }) => {
    if (status === "FAILED") {
      return `was failed.`;
    }
    if (!txnHash) {
      return `is broadcasting...`;
    } else {
      return `was sent successfully.`;
    }
  };

  const getTransactionStatus = (txn) => {
    if (txn.network == "lightning" || txn.network == "paxfulpay") {
      return (
        <>
          <FlexBox sx={{ alignItems: "center" }}>
            <CheckCircleIcon color="success" />
            Confirmed
          </FlexBox>
        </>
      );
    }

    if (txn.status == "BLOCKED" || txn.status == "FAILED") {
      return (
        <>
          <FlexBox sx={{ alignItems: "center" }}>
            <DangerousIcon color="error" /> Failed
          </FlexBox>
        </>
      );
    }

    if (!txn.txnHash) {
      return (
        <>
          <FlexBox sx={{ alignItems: "center" }}>
            <AccessTimeIcon color="primary" /> Broadcasting
          </FlexBox>
        </>
      );
    }

    if (txn.txnHash && txn.status !== "COMPLETED") {
      return (
        <>
          <FlexBox sx={{ alignItems: "center" }}>
            <AccessTimeIcon color="success" /> Ongoing confirmations
          </FlexBox>
        </>
      );
    }

    return (
      <>
        {/* <Icon className="bi bi-check-circle-fill fs-5 text-success"></Icon>{" "} */}
        <FlexBox sx={{ alignItems: "center" }}>
          <CheckCircleIcon color="success" /> &nbsp; Confirmed
        </FlexBox>
      </>
    );
  };

  const getTxnTitle = ({ network, classification, transactionType }) => {
    if (classification == "default") {
      return (
        getCurrencyNameByCode(asset.code) +
        " " +
        (transactionType == "debit" ? "Withdrawal" : "Deposit")
      );
    }
    if (classification == "va") {
      const desc =
        network == "lightning"
          ? "via Lightning Network"
          : `with ${getCurrencyNameByCode(asset.code)}`;

      return `Hire Virtual Assistant ${desc}`;
    }
    if (classification == "trading") {
      const desc =
        network == "lightning"
          ? "via Lightning Network"
          : `with ${getCurrencyNameByCode(asset.code)}`;
      return `Trading Volume Topup ${desc}`;
    }
  };

  const getAccountPFP = ({ paxful_profile }) => {
    let account = JSON.parse(paxful_profile);
    return (
      <>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <PaxfulProfilePic
            className={"me-1"}
            size={18}
            account={account}
            buyer={true}
          />
          <Box
            sx={{
              position: "absolute",
              top: -7,
              bottom: 0,
              left: 18,
              right: 0,
            }}
          >
            <Platform account={account} size={14} />
          </Box>
        </Box>
      </>
    );
  };

  const getConfirmationTitle = ({ classification, transactionType }) => {
    if (classification == "default") {
      return (
        <>
          {transaction.transactionType == "debit" ? "Withdrawal" : "Deposit"}{" "}
          Confirmation
        </>
      );
    }
    if (classification == "va") {
      return `Hire Confirmation`;
    }
    if (classification == "trading") {
      return `Topup Confirmation`;
    }
    if (classification == "subscription") {
      return `Membership Confirmation`;
    }
  };

  const getAmountTitle = ({ classification, transactionType }) => {
    if (classification == "default") {
      return (
        <>
          Amount{" "}
          {transaction.transactionType == "debit" ? "Withdrawn" : "Deposited"}
        </>
      );
    }
    if (classification == "va") {
      return `Virtual Assistant Fee`;
    }
    if (classification == "trading") {
      return `Topup Amount`;
    }
  };
  //   console.log("transaction", transaction);

  const getNetwork = ({ network, blockchain }) => {
    if (network === "lightning") {
      return "Lightning Network";
    } else if (network === "paxfulpay") {
      return "Paxful Pay";
    } else {
      return blockchain;
    }
  };

  // const getAssetBalance = () => {
  //   return profile.wallet.asset_balance[asset.assetID].crypto_balance;
  // };

  const transactionAnimations = {
    light: pendingConfirmationLight,
    dark: pendingConfirmationDark,
  };

  if (
    transaction &&
    (transaction.status === "COMPLETED" ||
      (transaction.transactionType == "debit" && transaction.txnHash))
  ) {
    transactionAnimations.light = transactionConfirmedLight;
    transactionAnimations.dark = transactionConfirmedDark;
  }

  const getNetworkFee = (transaction) => {
    console.log("live_rate", live_rate);
    return (
      <>
        {formatCryptoDecimal(transaction.networkFee, asset.decimals)}{" "}
        {asset.nativeAsset} (
        {formatCurrency(
          Number(
            transaction.networkFee * live_rate.rates[asset.nativeAsset]
          ).toFixed(8),
          userData.currency
        )}{" "}
        {userData.currency})
      </>
    );
  };

  const getUserData = async () => {
    const resp = await UserService.getUser(user_id);
    setUserData(resp);
  };
  useEffect(() => {
    getUserData();
  }, []);

  let textColor = settings.theme == "light" ? "black" : "white";

  return (
    <div>
      <Card>
        {transaction && asset && (
          <FlexBox p={2} py={3}>
            <BackButton
              onClick={() => navigate(`/dashboard/users/${user_id}`)}
            />
            <Small fontSize={18} fontWeight={600}>
              Venva Wallet
            </Small>
            <div className="divider-vertical all-wallets mx-5"></div>
            <Span
              sx={{ display: "flex", alignItems: "center" }}
              className="fs-2 d-flex align-items-center title"
            >
              <Box
                mx={2}
                sx={{ borderLeft: "1px solid lightgrey", height: "1.756rem" }}
              ></Box>
              {/* <img
                src={`/media/svg/coins/${asset?.code.toLowerCase()}.svg`}
                alt={asset.code}
                width={"20px"}
                className="me-2"
                style={{ marginRight: "10px" }}
              /> */}
              <CryptoSymbol id={asset.assetID} />
              <Small ml={1} fontSize={18} fontWeight={600}>
                {getTxnTitle(transaction)}
              </Small>
            </Span>
          </FlexBox>
        )}
        <Divider />

        <CardContent sx={{ padding: 0 }}>
          {/* <Box pt={2} pb={4}> */}
          {transaction && asset && (
            <Grid container columns={12}>
              <Grid item md={5}>
                <Grid>
                  <div className="col-left py-5 mb-0 mb-md-10">
                    <FlexBox
                      p={2}
                      sx={{ alignItems: "center" }}
                      className="ps-md-9 ps-4 currency-balance d-flex align-items-center"
                    >
                      <Balance
                        crypto={asset.code}
                        assetID={asset.assetID}
                        fiatBalance={getFormatedFiatAmount(
                          getAssetBalance(),
                          asset.assetID
                        )}
                        assetBalance={formatCryptoDecimal(
                          getAssetBalance(),
                          asset.decimals
                        )}
                      />
                    </FlexBox>
                    <Divider />
                    <div className="separator my-5"></div>

                    <FlexBox
                      sx={{ alignItems: "center", justifyContent: "center" }}
                      className="ps-md-9"
                    >
                      {settings.theme == "light" ? (
                        <AnimationPlayer
                          light={transactionAnimations.light}
                          width={"300px"}
                        />
                      ) : (
                        <AnimationPlayer
                          dark={transactionAnimations.dark}
                          width={"300px"}
                        />
                      )}
                      {/* <div className="animation-light">
                        <Lottie
                          options={{
                            animationData: transactionAnimations.light,
                          }}
                          width={"300px"}
                        />
                      </div>
                      <div className="animation-dark">
                        <Lottie
                          options={{
                            animationData: transactionAnimations.dark,
                          }}
                          width={"300px"}
                        />
                      </div> */}
                    </FlexBox>
                  </div>
                </Grid>
              </Grid>
              <Grid sx={{ borderLeft: "1px solid lightgrey" }} item md={7}>
                <Box px={2}>
                  <Grid>
                    <Typography py={3.2} fontSize={16} fontWeight={500}>
                      {getConfirmationTitle(transaction)}
                    </Typography>
                    <Divider style={{ margin: "5px 0" }} />
                    <Box mt={2}>
                      {transaction.classification === "default" && (
                        <>
                          {transaction.transactionType === "debit" ? (
                            <Typography fontSize={14}>
                              {asset.title} withdrawal{" "}
                              {getWithdrawalTxnDescription(transaction)}
                            </Typography>
                          ) : (
                            <Typography fontSize={14}>
                              {asset.title} deposit was received successfully.
                            </Typography>
                          )}
                        </>
                      )}
                      {transaction.classification === "va" && (
                        <Typography fontSize={14}>
                          Virtual assistant successfully assigned.
                        </Typography>
                      )}
                      {transaction.classification === "trading" && (
                        <Typography fontSize={14}>
                          Trading volume top-up was successful.
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid pt={2}>
                    <TransactionValue
                      title={"Cryptocurrency"}
                      value={
                        <FlexBox
                          sx={{ alignItems: "center" }}
                          className="d-flex align-items-center"
                        >
                          {/* <CryptoImg
                            currency={transaction.crypto_symbol}
                            size={20}
                          /> */}
                          <CryptoSymbol id={asset.assetID} />
                          &nbsp;&nbsp;
                          {asset.title}
                        </FlexBox>
                      }
                      valueClass={"fw-semibold text-gray-700 text-capitalize"}
                    />
                    {asset.assetID !== asset.nativeAsset && (
                      <TransactionValue
                        title={"Network"}
                        value={
                          <FlexBox
                            sx={{ alignItems: "center" }}
                            className="d-flex align-items-center"
                          >
                            <CryptoImg currency={asset.nativeAsset} size={20} />

                            <Small ml={0.5}> {getNetwork(transaction)}</Small>
                          </FlexBox>
                        }
                        valueClass={"fw-semibold text-gray-700 text-capitalize"}
                      />
                    )}

                    <TransactionValue
                      title={getAmountTitle(transaction)}
                      value={
                        <>
                          <FlexBox
                            sx={{
                              alignItems: "center",
                            }}
                          >
                            {/* <CryptoImg
                              currency={transaction.crypto_symbol}
                              size={20}
                            /> */}
                            <CryptoSymbol id={asset.assetID} />
                            &nbsp;&nbsp;
                            {formatCryptoDecimal(
                              transaction.crypto_amount,
                              asset.decimals
                            )}{" "}
                            {/* {transaction.crypto_amount}{" "} */}
                            {transaction.crypto_symbol} (
                            {live_rate &&
                              formatCurrency(
                                transaction.crypto_amount *
                                  live_rate.rates[transaction.crypto_symbol],
                                userData.currency
                              )}{" "}
                            {userData && userData.currency})
                            {/* (
                            {formatCurrency(
                              transaction.crypto_amount * transaction.rate
                            )}{" "}
                            USD) */}
                          </FlexBox>
                        </>
                      }
                    />
                    <TransactionValue
                      title="Status"
                      value={getTransactionStatus(transaction)}
                    />

                    {transaction.network !== "lightning" &&
                      transaction.network !== "paxfulpay" && (
                        <>
                          {transaction.transactionType == "debit" ? (
                            (console.log("TRANS", transaction),
                            (
                              <>
                                <TransactionValue
                                  title={"Withdraw address"}
                                  value={
                                    <>
                                      {transaction.destinationAddressData && (
                                        <FlexBox
                                          sx={{
                                            justifyContent: "end",
                                            flexWrap: "wrap",
                                          }}
                                          className="d-flex justify-content-end flex-wrap"
                                        >
                                          {transaction.destinationAddressData
                                            .account
                                            ? getAccountPFP(
                                                transaction
                                                  .destinationAddressData
                                                  .account
                                              )
                                            : null}{" "}
                                          &nbsp;&nbsp;
                                          {transaction.destinationAddressData
                                            .account && (
                                            <span className="fw-bold">
                                              {
                                                transaction
                                                  .destinationAddressData
                                                  .account.paxful_username
                                              }
                                              &nbsp;
                                            </span>
                                          )}
                                          <span className="fw-bold">
                                            <CryptoImg
                                              currency={asset.nativeAsset}
                                              size={15}
                                            />{" "}
                                            &nbsp;
                                            {
                                              transaction.destinationAddressData
                                                .title
                                            }
                                          </span>
                                        </FlexBox>
                                      )}
                                      <a
                                        href={`${asset.addressLink}/${transaction.destinationAddress}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="transaction-address d-flex align-items-center text-gray-600 text-hover-primary"
                                        style={{ lineHeight: "10px" }}
                                      >
                                        {transaction.destinationAddress}

                                        <span class="transaction-icon svg-icon svg-icon-muted svg-icon-3">
                                          <svg
                                            width="22"
                                            height="24"
                                            viewBox="0 -5 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              opacity="0.5"
                                              x="16.9497"
                                              y="8.46448"
                                              width="13"
                                              height="2"
                                              rx="1"
                                              transform="rotate(135 16.9497 8.46448)"
                                              fill="currentColor"
                                            ></rect>
                                            <path
                                              d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                                              fill="currentColor"
                                            ></path>
                                          </svg>
                                        </span>
                                      </a>
                                    </>
                                  }
                                />
                              </>
                            ))
                          ) : (
                            <>
                              <TransactionValue
                                title={"Sender address"}
                                value={
                                  <>
                                    <a
                                      href={`${asset.addressLink}/${transaction.sourceAddress}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="transaction-address d-flex align-items-center text-gray-600 text-hover-primary"
                                      style={{ lineHeight: "10px" }}
                                    >
                                      {transaction.sourceAddress}
                                      <span class="transaction-icon svg-icon svg-icon-muted svg-icon-3">
                                        <svg
                                          width="22"
                                          height="24"
                                          viewBox="0 -5 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            opacity="0.5"
                                            x="16.9497"
                                            y="8.46448"
                                            width="13"
                                            height="2"
                                            rx="1"
                                            transform="rotate(135 16.9497 8.46448)"
                                            fill="currentColor"
                                          ></rect>
                                          <path
                                            d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </span>
                                    </a>
                                  </>
                                }
                              />
                            </>
                          )}
                        </>
                      )}

                    <TransactionValue
                      title={"Timestamp"}
                      value={moment(
                        transaction.timestamp
                          ? transaction.timestamp
                          : transaction.createdAt
                      ).format("lll")}
                    />

                    {transaction.created_by &&
                      transaction.transactionType == "debit" && (
                        <TransactionValue
                          title={"Initiated by"}
                          value={
                            <>
                              <CreatedBy user={transaction.created_by} />
                            </>
                          }
                        />
                      )}

                    {transaction.sentByVendor && (
                      <TransactionValue
                        title={"Deposit received from Venva vendor"}
                        value={
                          <>
                            <CreatedBy
                              user={transaction.sentByVendor}
                              title={"Deposit received from Venva vendor:"}
                              otherUser
                            />
                          </>
                        }
                      />
                    )}
                    {transaction.sentToVendor && (
                      <TransactionValue
                        title={"Sent to Venva vendor"}
                        value={
                          <>
                            <CreatedBy
                              user={transaction.sentToVendor}
                              title={"Sent to Venva vendor:"}
                              otherUser
                            />
                          </>
                        }
                      />
                    )}
                    {transaction.txnHash && (
                      <>
                        <Grid pt={5}>
                          {live_rate && (
                            <TransactionValue
                              title="Network fee"
                              value={getNetworkFee(transaction)}
                              muted={true}
                              titleClass="text-muted fw-semibold"
                              valueClass="text-muted fw-semibold"
                            />
                          )}
                          <TransactionValue
                            title="Transaction Hash"
                            value={
                              <Box sx={{ color: "text.disabled" }}>
                                <Link
                                  sx={{
                                    textDecoration: "none", // Remove underline
                                    color: "#2499EF", // Apply muted color
                                  }}
                                  href={`${
                                    asset.explorerLink + transaction.txnHash
                                  }`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="transaction-address text-muted text-hover-primary"
                                >
                                  {transaction.txnHash}
                                  <IconButton size="small">
                                    <SvgIcon>
                                      <ArrowRight />
                                    </SvgIcon>
                                  </IconButton>
                                </Link>
                              </Box>
                            }
                            muted={true}
                            titleClass="text-muted fw-semibold"
                            valueClass="text-muted fw-semibold"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid
                    xs={12}
                    sx={{
                      display: "flex",
                      marginTop: "30px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      href={`${asset.explorerLink + transaction.txnHash}`}
                      target="_blank"
                      rel="noreferrer"
                      disabled={!transaction.txnHash}
                      variant="contained"
                      color="primary"
                      size="small"
                      endIcon={<ArrowRight />}
                      sx={{
                        "&.Mui-disabled": {
                          color: "white", // apply white color when button disable
                        },
                      }}
                    >
                      View in Explorer
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
          {/* </Box> */}
        </CardContent>
      </Card>
    </div>
  );
}

export default ViewTransaction;
