import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { H6, Paragraph, Small, Span, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, ContentCopy } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import styled from "@emotion/styled";
import CustomChip from "components/shared/CustomChip";
import Platform from "components/shared/Platform";
import FlexBox from "components/flexbox/FlexBox";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import ATooltip from "components/shared/ATooltip";
import CheckmarkCircle from "icons/CheckmarkCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import OnlineStatus from "components/shared/OnlineStatus";
import MfaCheck from "components/shared/MfaCheck";
import DeleteButton from "page-sections/profiles/profile/DeleteButton";
import EditButton from "page-sections/profiles/profile/EditButton";
import PasswordButton from "page-sections/profiles/profile/PasswordButton";
import RoleBadge from "utils/RoleBadge";
import LockButton from "page-sections/profiles/profile/overview/LockButton";
import DiscordAvatar from "components/avatars/DiscordAvatar";
import GoogleIcon from "icons/GoogleIcon";
import SignInMethod from "components/shared/SignInMethod";
import notification from "helpers/notifications";
import PersonOffButton from "page-sections/profiles/profile/SessionButton";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)`
    padding: 4,
    minWidth: 60,
    marginRight: 3,
  `;

const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 10px",
  color: theme.palette.text.primary,
}));

const GooglePicture = styled("img")`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

// anchorEl,
// handleClick,
// _handleClose,
// _open,
// id
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};

export const ColumnShape = (
  confirmDeleteUser,
  changePassword,
  editUser,
  disableMFA
) => [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
            cursor: "pointer",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "8px" }}>Date </Box>,
    accessor: "createdAt",
    Cell: ({ value }) => (
      <Box
        sx={{
          minWidth: "150px",
        }}
      >
        <Paragraph fontSize={13} color="text.secondary">
          <FormattedDateTime date={value} />
        </Paragraph>
        <Small
          sx={{
            width: 100,
            fontSize: 10,
            color: "#fff",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor: "lightgrey",
            color: "black",
          }}
        >
          <FromNow date={value} />
        </Small>
      </Box>
    ),
  },
  // User

  {
    Header: () => <Box sx={{ marginLeft: "11px" }}>NAME </Box>,
    accessor: "name",
    Cell: ({ row }) => {
      const {
        name,
        first_name,
        last_name,
        email,
        email_verified,
        country,
        mobile_number,
        mobile_verified,
        account,
        role,
        plan,
        subscriptionEndsAt,
        subscriptionStartedAt,
        autoRenew,
        discord,
      } = row.original;
      let value = false;
      if (email != null) {
        value = true;
      }

      return (
        <>
          <FlexBox>
            <Box mt={1}>
              <UserSymbol
                user={row.original}
                size={30}
                showOnline={true}
                isBottomZero={0}
              />
            </Box>
            <Box ml={1}>
              <Link
                state={{ id: row?.original?._id }}
                to={{
                  pathname: `/dashboard/users/${
                    row?.original?.role == "admin"
                      ? row?.original?._id
                      : row?.original?.parent?._id
                  }`,
                }}
              >
                <FlexRowAlign justifyContent="start">
                  <Span
                    sx={{
                      fontWeight: "bold",
                      marginRight: "4px",
                    }}
                  >
                    {/* {first_name && first_name} {last_name && last_name} */}
                    {name}
                  </Span>

                  {country && (
                    <Span>
                      <CountryFlag code={country} width={"20px"} />
                    </Span>
                  )}
                  {plan && (
                    <ATooltip
                      content={
                        <>
                          {" "}
                          <Box px={2} py={1}>
                            {autoRenew ? (
                              <Paragraph fontSize={12} color={"text.secondary"}>
                                Renew on :{" "}
                                {<FormattedDate date={subscriptionEndsAt} />}
                              </Paragraph>
                            ) : (
                              <Paragraph fontSize={12} color={"text.secondary"}>
                                Active until :{" "}
                                {<FormattedDate date={subscriptionEndsAt} />}
                              </Paragraph>
                            )}
                          </Box>
                        </>
                      }
                    >
                      {/* <Small
                          ml={2}
                          sx={{
                            width: 100,
                            fontSize: 10,
                            borderRadius: "4px",
                            textAlign: "center",
                            padding: ".2rem 1rem",
                            backgroundColor:
                              plan !== "professional" ? "#00e5ff" : "#ffc107",
                          }}
                        >
                          {plan.charAt(0).toUpperCase() +
                            plan.slice(1).toLowerCase()}
                        </Small> */}
                    </ATooltip>
                  )}
                </FlexRowAlign>
                <FlexRowAlign justifyContent="start">
                  <Span
                    sx={{
                      color: "grey.400",
                      marginRight: "2px",
                    }}
                  >
                    {value ? email.replace(/<|>/g, "") : mobile_number}
                  </Span>
                  {value ? (
                    email_verified == true ? (
                      <StyledCheckMark />
                    ) : (
                      <StyledCancelIcon />
                    )
                  ) : mobile_verified == true ? (
                    <StyledCheckMark />
                  ) : (
                    <StyledCancelIcon />
                  )}
                </FlexRowAlign>
              </Link>

              <FlexBox>
                <RoleBadge role={role} />
              </FlexBox>

              {/* account */}
              {/* <FlexBox
                    // ml={4}
                    sx={{ alignItems: "center" }}
                    justifyContent="start"
                  >
                    {account?.length > 0 && (
                      <FlexBox>
                        <AccountGroup accounts={account} />
                      </FlexBox>
                    )}
                  </FlexBox> */}
            </Box>
          </FlexBox>
        </>
      );
    },
  },

  //   {
  //     Header: () => <Box sx={{ marginLeft: "10px" }}>LAST ACTIVE </Box>,
  //     accessor: "last_active",
  //     Cell: ({ row }) => {
  //       return (
  //         <>
  //           <Box
  //             sx={{
  //               minWidth: "150px",
  //             }}
  //           >
  //             <OnlineStatus member={row.original} is_live={false} />
  //           </Box>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     Header: () => <Box sx={{ marginLeft: "8px" }}>SIGN-IN METHODS </Box>,
  //     accessor: "role",
  //     Cell: ({ row }) => {
  //       const {
  //         discord,
  //         googleId,
  //         paxfulId,
  //         noonesId,
  //         isGoogleSignup,
  //         isPaxfulSignup,
  //         isNoonesSignup,
  //         isDiscordSignup,
  //       } = row.original;

  //       const noonesAccounts = row.original.accounts.find(
  //         ({ platform }) => platform === "noones"
  //       );
  //       const paxfulAccounts = row.original.accounts.find(
  //         ({ platform }) => platform === "paxful"
  //       );
  //       const loginMehtod = {
  //         paxful: paxfulAccounts,
  //         noones: noonesAccounts,
  //       };
  //       console.log("loginMehtod", loginMehtod);

  //       return (
  //         <>
  //           <FlexBox sx={{ alignItems: "start" }}>
  //             <FlexBox sx={{ alignItems: "center" }}>
  //               {isDiscordSignup && (
  //                 <ATooltip
  //                   content={
  //                     <SignInMethod
  //                       user={row.original}
  //                       discordId={discord}
  //                       email={row.original.discord.username}
  //                     />
  //                   }
  //                 >
  //                   {/* <DiscordAvatar discordId={discord} width={24} /> */}
  //                   <FlexBox sx={{ alignItems: "center" }}>
  //                     <img
  //                       src={"/media/logos/discord-logo-png-7617.png"}
  //                       width={"30px"}
  //                     />
  //                   </FlexBox>
  //                 </ATooltip>
  //               )}
  //             </FlexBox>
  //             <FlexBox sx={{ alignItems: "center" }}>
  //               {isGoogleSignup && (
  //                 <ATooltip
  //                   content={
  //                     <SignInMethod
  //                       user={row.original}
  //                       pfp={googleId?.picture}
  //                       email={googleId.email}
  //                     />
  //                   }
  //                 >
  //                   <FlexBox sx={{ alignItems: "center", marginTop: "3px" }}>
  //                     <GoogleIcon
  //                       sx={{
  //                         width: "30px",
  //                       }}
  //                     />
  //                   </FlexBox>
  //                 </ATooltip>
  //               )}
  //             </FlexBox>
  //             <FlexBox sx={{ alignItems: "center" }}>
  //               {loginMehtod.paxful && isPaxfulSignup && (
  //                 <ATooltip
  //                   content={
  //                     <SignInMethod
  //                       user={row.original}
  //                       pfp={paxfulId?.picture}
  //                       email={paxfulId?.email}
  //                     />
  //                   }
  //                 >
  //                   <Platform platform={"paxful"} size={30} />
  //                 </ATooltip>
  //               )}
  //             </FlexBox>
  //             <FlexBox sx={{ alignItems: "center" }}>
  //               {loginMehtod.noones && isNoonesSignup && (
  //                 <ATooltip
  //                   content={
  //                     <SignInMethod
  //                       user={row.original}
  //                       pfp={noonesId?.picture}
  //                       email={noonesId?.email}
  //                     />
  //                   }
  //                 >
  //                   <Platform platform={"noones"} size={30} />
  //                 </ATooltip>
  //               )}
  //             </FlexBox>
  //           </FlexBox>
  //         </>
  //       );
  //     },
  //   },

  //   Vendor
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>VENDOR </Box>,
    accessor: "abcdef",
    Cell: ({ row }) => {
      const user = row.original;
      let parent = user.parent ? true : false;
      console.log("MASTERUSER", row);
      return (
        <>
          {parent && (
            <FlexBox sx={{ alignItems: "center" }}>
              <UserSymbol
                user={user.parent}
                size={30}
                showOnline={true}
                isBottomZero={0}
              />

              <Box
                sx={{
                  marginLeft: "10px !important",
                }}
              >
                <Paragraph fontSize={13} color="text.secondary">
                  <Link to={`/dashboard/users/${user.parent._id}`}>
                    {user.parent.username}
                  </Link>
                </Paragraph>
              </Box>
            </FlexBox>
          )}
        </>
      );
    },
  },

  //   {
  //     Header: () => <Box sx={{ marginLeft: "8px" }}>TWO-STEP </Box>,
  //     accessor: "mfa_enabled",
  //     Cell: ({ value }) => {
  //       return (
  //         <>
  //           <MfaCheck value={value} />
  //         </>
  //       );
  //     },
  //   },
  {
    Header: () => <Box sx={{ textAlign: "end" }}>ACTIONS </Box>,
    accessor: "is_deleted",
    Cell: ({ row }) => {
      const { is_deleted } = row.original;
      return (
        <>
          <Box>
            <FlexBox justifyContent="end">
              <ATooltip content={"Delete User"}>
                {
                  <DeleteButton
                    disabled={is_deleted}
                    // disabled={true}
                    onClick={() => confirmDeleteUser(row.original, "delete")}
                  />
                }
              </ATooltip>
            </FlexBox>
          </Box>
        </>
      );
    },
  },
];
export default ColumnShape;
