import { Edit, ReorderTwoTone } from "@mui/icons-material";
import { IconButton, styled, Button, Box } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import FlexBox from "components/flexbox/FlexBox";
import { Link } from "react-router-dom";
import { Tiny } from "components/Typography";
import { ArrowOutward, ArrowRight } from "@mui/icons-material";

import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import TradeStatus from "components/shared/TradeStatus";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import OfferType from "components/shared/OfferType";
import { FlagAndPlatform } from "./PartnerDetail";
import CustomPopover from "components/CustomPopover";
import { VATooltip } from "./Chat";
import ATooltip from "components/shared/ATooltip";
import UserInfo from "components/shared/UserInfo";
import platforms from "data/platforms";
import { formatCurrency } from "utils/currencies";
import FormattedBalance from "components/shared/FormattedBalance";
import CryptoBalance from "components/shared/CryptoBalance";
import TradeStatIcons from "components/shared/TradeStatIcons";
import TimeElapsedTimer from "components/TimeElapsedTimer";
import moment from "moment-timezone";
import Countdown from "react-countdown";
import PaxfulAccount from "components/shared/PaxfulAccount";
import CreatedBy from "components/shared/CreatedBy";
import { ReleasedBy } from "page-sections/user-list/TradeHistoryColumnShape";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));
const countDownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (!completed) {
    return (
      <span className="fs-7">
        {hours > 0 ? `${hours}h` : ""} {minutes > 0 ? `${minutes}m` : ""}{" "}
        {seconds}s
      </span>
    );
  }
};
const columnShape = (userdata) => {
  return [
    {
      Header: "DATE",
      accessor: "startedAt",
      Cell: ({ row }) => {
        const { startedAt, cancelledAt, completedAt } = row.original;
        return (
          <Box>
            {/* <Paragraph fontSize={13} color="text.secondary">
              StartedAt : <FormattedDateTime date={startedAt} />
            </Paragraph> */}
            <Paragraph fontSize={13} color="text.secondary">
              {completedAt ? (
                <FormattedDateTime date={completedAt} />
              ) : (
                <FormattedDateTime date={startedAt} />
              )}
            </Paragraph>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Small
                sx={{
                  width: 100,
                  fontSize: 10,
                  borderRadius: "4px",
                  textAlign: "center",
                  padding: ".2rem 1rem",
                  backgroundColor: "lightgrey",
                  color: "black",
                }}
              >
                <FromNow date={completedAt ? completedAt : startedAt} />
              </Small>
            </div>
          </Box>
        );
      },
    },

    {
      Header: "ACCOUNT",
      accessor: "account",
      Cell: ({ row }) => {
        const { account } = row.original;
        return (
          <>
            {
              <FlexBox justifyContent="start" alignItems="center">
                <PaxfulAccount item={account} size={25} />
                {/* <PaxfulProfilePic size={30} account={account} />
                {account.paxful_username && (
                  <>
                    <Tiny fontSize={12} fontWeight={500}>
                      <a
                        style={{
                          marginLeft: "3px",
                        }}
                        href={
                          `https://${account.platform}.com/user/` +
                          account.paxful_username
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {account.paxful_username}
                      </a>
                    </Tiny>
                    <Box ml={1}>
                      <Platform size={20} platform={account.platform} />
                    </Box>
                  </>
                )} */}
              </FlexBox>
            }
            {/* <FlexRowAlign justifyContent="start">
                <Span
                  sx={{
                    color: "grey.400",
                    marginRight: "2px",
                  }}
                >
                  {email}
                </Span>
                {email_verified == true ? (
                  <StyledCheckMark />
                ) : (
                  <StyledCancelIcon />
                )}
              </FlexRowAlign> */}
          </>
        );
      },
    },
    {
      Header: "TRADE HASH",
      accessor: "trade.trade_hash",
      Cell: ({ row, onClick }) => {
        const {
          account,
          trade,
          user,
          status,
          trade_chat,
          unread_messages,
          unread_files,
          otp_verified,
          trade_security,
          trade_attachments,
        } = row.original;

        const totalAttachmentCount = trade_attachments?.messages?.reduce(
          (total, message) => {
            return total + (message?.message?.text?.files?.length || 0);
          },
          0
        );

        return (
          <Box sx={{ minWidth: 150 }}>
            <Paragraph
              sx={{
                cursor: "pointer",
              }}
              color="primary.main"
              onClick={() => onClick(trade.trade_hash)}
            >
              {trade.trade_hash}
              <span class="transaction-icon svg-icon svg-icon-muted svg-icon-3">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 -5 22 22"
                  fill="none"
                  // xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="16.9497"
                    y="8.46448"
                    width="13"
                    height="2"
                    rx="1"
                    transform="rotate(135 16.9497 8.46448)"
                    fill="currentColor"
                  ></rect>
                  <path
                    d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </Paragraph>{" "}
            <FlexBox mt={1} sx={{ cursor: "default" }}>
              <TradeStatIcons
                chatCount={
                  trade_chat && trade_chat?.messages.length > 0
                    ? trade_chat?.messages.length -
                      trade_attachments?.messages.length
                    : 0
                }
                chatDisabled={Number(unread_messages) < 3}
                chatBlink={Number(unread_messages) > 3}
                attachmentCount={totalAttachmentCount}
                attachmentDisabled={Number(unread_files) < 3}
                attachmentBlink={Number(unread_files) > 3}
                otp_verified={otp_verified}
                trade_security={trade_security}
              />
            </FlexBox>
          </Box>
        );
      },
    },
    {
      Header: () => <Box sx={{ width: "135px" }}> PAYMENT METHOD</Box>,
      accessor: "trade.payment_method_name",
      Cell: ({ row }) => {
        let payment_method_name = row.original.trade.payment_method_name;
        let offer_type = row.original.trade.offer_type;
        return (
          <Box
            sx={{
              maxWidth: "170px",
              display: "inline-block",
            }}
          >
            <Small fontSize={13} color="text.secondary">
              {payment_method_name}
            </Small>
            {/* <OfferType value={offer_type} /> */}
          </Box>
        );
      },
    },
    {
      Header: "",
      accessor: "fgf",
      Cell: ({ row }) => {
        const { trade } = row.original;
        return (
          <FlexBox>
            <OfferType value={trade.offer_type} />
          </FlexBox>
        );
      },
    },
    // {
    //   Header: "PLATFORM",
    //   accessor: "account.platform",
    //   Cell: ({ value }) => (
    //     <>
    //       <Platform size={30} platform={value} />
    //     </>
    //   ),
    // },
    {
      Header: "BUYER",
      accessor: "buyer",
      Cell: ({ row }) => {
        let value = row.original.buyer;
        let platform = row.original.account.platform;
        let partner_location = row.original.partner_location;

        return (
          <FlexBox justifyContent="start" alignItems="center">
            <PaxfulProfilePic size={25} account={value.profile} buyer />
            {value.username && (
              <Tiny fontSize={12} fontWeight={500}>
                <a
                  style={{
                    marginLeft: "3px",
                  }}
                  href={`https://${platform}.com/user/` + value.username}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {value.username}
                </a>
              </Tiny>
            )}
            {partner_location && (
              <FlagAndPlatform
                CountryName={partner_location.detected_location.localized_name}
                country={partner_location.detected_location.iso}
                platform={platform}
              />
            )}
          </FlexBox>
        );
      },
    },
    {
      Header: "AMOUNT",
      accessor: "amount",
      Cell: ({ row }) => {
        let item = row.original;
        let user = userdata;
        return (
          <>
            <FlexBox
              sx={{
                // minHeight: "70px",
                minWidth: "150px",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "default",
              }}
            >
              <Small fontWeight={"bold"}>
                <FormattedBalance
                  offer_type={item.trade.offer_type}
                  code={item.trade.fiat_currency_code}
                  amount={item.trade.fiat_amount_requested}
                  status={item.status}
                  user={user}
                />
              </Small>
              <span className="cursor-default text-gray-700">
                <CryptoBalance
                  offer_type={item.trade.offer_type}
                  code={item.trade.crypto_currency_code}
                  amount={item.trade.crypto_amount_total}
                  status={item.status}
                  user={user}
                />
              </span>
              {item.status !== "cancelled" && (
                <div className="fs-7 cursor-default">
                  Profit:{" "}
                  <Small color="success.main">
                    {formatCurrency(
                      item.estimated_profit,
                      item.trade.fiat_currency_code
                    )}
                  </Small>
                </div>
              )}
            </FlexBox>
          </>
        );
      },
    },
    {
      Header: "FEE",
      accessor: "fbhdbf",
      Cell: ({ row }) => {
        let item = row.original;
        return (
          <>
            {item?.atlantis_fee_details?.atlantis_fee ? (
              <Paragraph color="text.secondary" fontSize={15} fontWeight="600">
                {formatCurrency(item.atlantis_fee_details.atlantis_fee)}{" "}
              </Paragraph>
            ) : (
              <Paragraph color="text.secondary" fontWeight="600" fontSize={15}>
                $0
              </Paragraph>
            )}
          </>
        );
      },
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => {
        const {
          releasedBy,
          status,
          vaActivity,
          hireRequestId,
          is_synced,
          account,
          trade,
          buyer,
          cancelledBy,
          user,
        } = row.original;
        // console.log("RoWWs", row);
        return (
          <Box
            sx={{
              minWidth: "150px",
              cursor: "default",
            }}
          >
            {<TradeStatus _trade={row.original} />}

            <div className="time-wrap">
              {row.original.status === "new" && (
                <>
                  <ATooltip content="Time Remaining">
                    <Small
                      mt={1}
                      fontSize={12}
                      fontWeight={600}
                      color="error.main"
                      className="text-danger fs-7 fw-bolder cursor-default"
                    >
                      <Countdown
                        date={
                          moment(row.original.startedAt)
                            .add(row.original.trade.payment_window, "minutes")
                            .unix() * 1000
                        }
                        renderer={countDownRenderer}
                      />
                    </Small>
                  </ATooltip>
                </>
              )}
              {row.original.status === "paid" && (
                <>
                  <ATooltip content="Time Elapsed">
                    <Small
                      mt={1}
                      color="primary.main"
                      fontSize={12}
                      fontWeight={600}
                    >
                      <TimeElapsedTimer
                        start={row.original.startedAt}
                        id={row.original._id}
                      />
                    </Small>
                  </ATooltip>
                </>
              )}
            </div>

            {cancelledBy && status == "cancelled" && (
              <ReleasedBy
                title={"Cancelled By"}
                user={cancelledBy}
                role={cancelledBy.role}
                hireRequestId={hireRequestId}
              />
            )}

            {releasedBy && status == "released" && (
              <>
                <Box>
                  <Small
                    mt={1}
                    sx={{
                      display: "block",
                    }}
                    className="text-muted"
                  >
                    Released by
                  </Small>
                  <CreatedBy user={releasedBy} otherUser />
                  {/* <ATooltip
                    content={<UserInfo user={releasedBy} is_live={false} />}
                  >
                    <Small
                      fontWeight="bold"
                      fontSize="8"
                      color="primary.main"
                      className="fs-7 fw-bolder text-primary cursor-pointer"
                    >
                      {releasedBy.first_name}
                    </Small>
                  </ATooltip> */}
                  {/* {releasedBy.role === "va" ? (
                    <CustomPopover
                      target={
                        <span className="fs-7 fw-bolder text-primary cursor-pointer">
                          {" "}
                          {releasedBy.first_name}
                        </span>
                      }
                    >
                      <VATooltip
                        user={releasedBy}
                        hireRequestId={hireRequestId}
                      />
                    </CustomPopover>
                  ) : (
                    <ATooltip content={<UserInfo user={releasedBy} />}>
                      <Paragraph
                        color={"primary.main"}
                        fontSize={11}
                        className="fs-7 fw-bolder text-primary cursor-pointer"
                      >
                        {" "}
                        {releasedBy.first_name}
                      </Paragraph>
                    </ATooltip>
                  )} */}
                </Box>
              </>
            )}
            {!releasedBy && status == "released" && is_synced && (
              <Box>
                {" "}
                <Paragraph mt={1} className="fs-8 release d-block">
                  <Small color="text.secondary" fontSize={11}>
                    Synced from {platforms[account.platform].title}
                  </Small>
                </Paragraph>{" "}
              </Box>
            )}

            {!releasedBy &&
              status == "released" &&
              !is_synced &&
              trade.offer_type == "sell" && (
                <Box mt={1} className="fs-8 release d-block">
                  <Small color="text.secondary" fontSize={11}>
                    Released on {platforms[account.platform].title}
                  </Small>
                </Box>
              )}
            {!releasedBy &&
              status == "released" &&
              !is_synced &&
              trade.offer_type == "buy" && (
                <FlexBox mt={1}>
                  Released by{" "}
                  <ATooltip
                    content={
                      <FlexBox sx={{ alignItems: "center" }}>
                        <Paragraph color={"text.secondary"} fontSize={11}>
                          {buyer.username}
                        </Paragraph>
                        <Small ml={1}>
                          <Platform platform={account.platform} size={15} />
                        </Small>
                      </FlexBox>
                    }
                  >
                    <Box ml={1} style={{ position: "relative" }}>
                      <PaxfulProfilePic account={buyer} size={15} buyer />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "-5px",
                          right: "-10px",
                        }}
                      >
                        <Platform
                          size={15}
                          platform={account.platform}
                          className="h-15px platform-icon"
                        />
                      </Box>
                    </Box>
                  </ATooltip>
                </FlexBox>
              )}
          </Box>
        );
      },
    },
    {
      Header: "ACTIONS",
      accessor: "",
      Cell: ({ row, onClick }) => {
        const { trade } = row.original;
        // console.log(row.original);
        // console.log(trade.trade_hash)
        return (
          <>
            {/* <StyledButton variant="outlined" color="primary" size="small">
              <Link to={`/dashboard/users/${_id}/trade`} color="info">
                View
              </Link>
            </StyledButton> */}
            <StyledButton
              onClick={() => onClick(trade.trade_hash)}
              variant="outlined"
              color="primary"
              size="small"
            >
              View
            </StyledButton>
          </>
        );
      },
    },
  ];
};

export default columnShape;
