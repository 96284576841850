import { ContentCopy, Edit } from "@mui/icons-material";
import { IconButton, styled, Button, Box, Tooltip } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import FlexBox from "components/flexbox/FlexBox";
import { Link } from "react-router-dom";
import { Tiny } from "components/Typography";
import { ArrowOutward, ArrowRight } from "@mui/icons-material";

import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import TradeStatus, { TradesStatus } from "components/shared/TradeStatus";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import BuyerProfilePic from "components/shared/BuyerProfilePic";
import PaxfulAccount from "components/shared/PaxfulAccount";
import { FlagAndPlatform } from "page-sections/profiles/profile/Trades/PartnerDetail";
import UserSymbol from "components/shared/UserSymbol";
import OfferType from "components/shared/OfferType";
import { formatCurrency } from "utils/currencies";
import CryptoBalance from "components/shared/CryptoBalance";
import FormattedBalance from "components/shared/FormattedBalance";
import TimeElapsedTimer from "components/TimeElapsedTimer";
import moment from "moment-timezone";
import Countdown from "react-countdown";
import ATooltip from "components/shared/ATooltip";
import notification from "helpers/notifications";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));

const countDownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (!completed) {
    return (
      <span className="fs-7">
        {hours > 0 ? `${hours}h` : ""} {minutes > 0 ? `${minutes}m` : ""}{" "}
        {seconds}s
      </span>
    );
  }
};
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};

const ActiveTradeColumnShape = [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
            marginRight: "10px",
            cursor: "pointer",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "12px" }}>DATE </Box>,
    accessor: "createdAt",
    Cell: ({ value }) => {
      return (
        <Box
          sx={{
            minWidth: "148px",
          }}
        >
          <Paragraph fontSize={13} color="text.secondary">
            <FormattedDateTime date={value} />
          </Paragraph>
          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#fff",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: "lightgrey",
              color: "black",
            }}
          >
            <FromNow date={value} />
          </Small>
        </Box>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "14px" }}>VENDOR </Box>,
    accessor: "user",
    Cell: ({ value }) => {
      const user = value[0];
      return (
        <>
          <FlexBox sx={{ alignItems: "center" }}>
            <span>
              {" "}
              <UserSymbol
                user={user}
                size={25}
                showOnline={true}
                isBottomZero={0}
              />
            </span>

            <Box
              sx={{
                marginLeft: "10px !important",
              }}
            >
              <Link to={`/dashboard/users/${user._id}`}>{user.username}</Link>
            </Box>
          </FlexBox>
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "11px" }}>TRADE HASH </Box>,
    accessor: "trade.trade_hash",
    Cell: ({ row, onClick }) => {
      const { user, account, trade } = row.original;
      const { _id } = user[0];
      return (
        <Box sx={{ minWidth: 150 }}>
          {/* <Paragraph
            sx={{
              cursor: "pointer",
            }}
            color="primary.main"
            onClick={() => onClick(trade.trade_hash)}
          >
            {trade.trade_hash}
          </Paragraph>{" "} */}
          {/* <Paragraph fontSize={12} fontWeight={"bold"}>
            {trade.trade_hash}
          </Paragraph> */}
          <Link to={`/dashboard/users/${_id}/?trade=${trade.trade_hash}`}>
            {trade.trade_hash}
            <span class="transaction-icon svg-icon svg-icon-muted svg-icon-3">
              <svg
                width="20"
                height="20"
                viewBox="0 -5 22 22"
                fill="none"
                // xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="16.9497"
                  y="8.46448"
                  width="13"
                  height="2"
                  rx="1"
                  transform="rotate(135 16.9497 8.46448)"
                  fill="currentColor"
                ></rect>
                <path
                  d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </Link>
        </Box>
      );
    },
  },

  {
    Header: () => <Box sx={{ marginLeft: "12px" }}>ACCOUNT</Box>,
    accessor: "account.paxful_username",
    Cell: ({ row }) => {
      const { paxful_username, platform } = row.original.account;
      return <>{<PaxfulAccount item={row.original.account} size={25} />}</>;
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "8px" }}>PAYMENT METHOD </Box>,
    accessor: "trades",
    Cell: ({ row }) => {
      const { trade } = row.original;
      return (
        <FlexBox sx={{ minWidth: "180px", cursor: "text" }}>
          <Paragraph mr={2} fontSize={12}>
            {" "}
            {trade.payment_method_name}
          </Paragraph>
        </FlexBox>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "8px" }}> </Box>,
    accessor: "offertype",
    Cell: ({ row }) => {
      const { trade } = row.original;
      return <OfferType value={trade.offer_type} />;
    },
  },

  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>BUYER </Box>,
    accessor: "buyer",
    Cell: ({ row }) => {
      const { buyer, partner_location, account } = row.original;

      return (
        <FlexBox justifyContent="start" alignItems="center">
          <PaxfulProfilePic size={25} account={buyer[0].profile} buyer />
          {/* {buyer[0] && <BuyerProfilePic size={30} buyer={buyer[0]} />} */}
          {buyer[0].username && (
            <>
              <Tiny fontSize={12} fontWeight={500}>
                <a
                  style={{
                    marginLeft: "3px",
                  }}
                  href={
                    `https://${account.platform}.com/user/` + buyer[0].username
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {buyer[0].username}
                  {/* <ArrowOutward
                    sx={{
                      fontSize: 14,
                      marginLeft: "1px",
                    }}
                  /> */}
                </a>
              </Tiny>
              {partner_location && (
                <FlagAndPlatform
                  CountryName={
                    partner_location.detected_location.localized_name
                  }
                  country={partner_location.detected_location.iso}
                  platform={account.platform}
                />
              )}
            </>
          )}
        </FlexBox>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "8px" }}>FEE</Box>,
    accessor: "fbhdbf",
    Cell: ({ row }) => {
      let item = row.original;
      return (
        <>
          {item?.atlantis_fee_details?.atlantis_fee ? (
            <Paragraph color="text.secondary" fontSize={15} fontWeight="600">
              {formatCurrency(item.atlantis_fee_details.atlantis_fee)}{" "}
            </Paragraph>
          ) : (
            <Paragraph color="text.secondary" fontWeight="600" fontSize={15}>
              $0
            </Paragraph>
          )}
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>AMOUNT </Box>,
    accessor: "amount",
    Cell: ({ row }) => {
      let item = row.original;
      return (
        <FlexBox
          sx={{
            minHeight: "70px",
            minWidth: "150px",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "default",
          }}
        >
          <Small fontWeight={"bold"}>
            <FormattedBalance
              offer_type={item.trade.offer_type}
              code={item.trade.fiat_currency_code}
              amount={item.trade.fiat_amount_requested}
              status={item.status}
              user={item.user[0]}
            />
          </Small>
          <span className="cursor-default text-gray-700">
            <CryptoBalance
              offer_type={item.trade.offer_type}
              code={item.trade.crypto_currency_code}
              amount={item.trade.crypto_amount_total}
              status={item.status}
              user={item.user[0]}
            />
          </span>
          {item.status !== "cancelled" && (
            <div className="fs-7 cursor-default">
              Profit:{" "}
              <Small color="success.main">
                {formatCurrency(
                  item.estimated_profit,
                  item.trade.fiat_currency_code
                )}
              </Small>
            </div>
          )}
        </FlexBox>
      );
    },
  },

  {
    Header: "STATUS",
    accessor: "status",
    Cell: ({ row }) => {
      console.log("row.original.startedAt", row.original.startedAt);
      console.log(
        "row.original.payment_window",
        row.original.trade.payment_window
      );
      return (
        <Box
          sx={{
            minWidth: "110px",
            cursor: "default",
          }}
        >
          <TradeStatus _trade={row.original} fontSize="10px" />
          <div className="time-wrap">
            {row.original.status === "new" && (
              <>
                <ATooltip content="Time Remaining">
                  <Small
                    mt={1}
                    fontSize={12}
                    fontWeight={600}
                    color="error.main"
                    className="text-danger fs-7 fw-bolder cursor-default"
                  >
                    <Countdown
                      date={
                        moment(row.original.startedAt)
                          .add(row.original.trade.payment_window, "minutes")
                          .unix() * 1000
                      }
                      renderer={countDownRenderer}
                    />
                  </Small>
                </ATooltip>
              </>
            )}
            {row.original.status === "paid" && (
              <>
                <ATooltip content="Time Elapsed">
                  <Small
                    mt={1}
                    color="primary.main"
                    fontSize={12}
                    fontWeight={600}
                    sx={{
                      cursor: "default",
                    }}
                  >
                    <TimeElapsedTimer
                      start={row.original.startedAt}
                      id={row.original._id}
                    />
                  </Small>
                </ATooltip>
              </>
            )}
          </div>
        </Box>
      );
    },
  },
];
export default ActiveTradeColumnShape;
