import { TabContext, TabPanel } from "@mui/lab";
import { Box } from "@mui/system";
import OfferType from "components/shared/OfferType";
import Activity from "page-sections/profiles/profile/activity";
import Campaigns from "page-sections/profiles/profile/campaigns";
import Connections from "page-sections/profiles/profile/connections";
import Documents from "page-sections/profiles/profile/documents";
import Layout from "page-sections/profiles/profile/Layout";
import Overview from "page-sections/profiles/profile/overview";
import Projects from "page-sections/profiles/profile/projects";
import Trades from "page-sections/profiles/profile/Trades/Trades";
import View from "page-sections/profiles/profile/Trades/View";
import Offer from "page-sections/profiles/profile/offer/Offers";
import Transaction from "page-sections/profiles/profile/Transactions/Transactions";
import { useState, useEffect, createContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import TradeServices from "services/TradeService";
import UserService from "services/UserService";
import OfferService from "services/OffersService";
import Offers from "page-sections/profiles/profile/offer/Offers";
import UserWebhook from "page-sections/profiles/profile/userWebhook/UserWebhook";
import ActivityLog from "pages/ActivityLog";
import UserActivityLog from "page-sections/profiles/profile/userActivityLog/UserActivityLog";
import UserPaxfulApiLog from "page-sections/profiles/profile/userPaxfulApilog/UserPaxfulApilog";
import UserHiringRequest from "page-sections/profiles/profile/hiringRequest/UserHiringRequest";
import PaxfulTransactions from "page-sections/profiles/profile/paxfulTransaction/PaxfulTransactions";
import { CircularProgress } from "@mui/material";
import BlockchainTransaction from "page-sections/profiles/profile/blockchainTransaction/BlockchainTransaction";
import Wallet2 from "page-sections/profiles/profile/wallet2/wallet2";
import Wallet from "page-sections/profiles/profile/wallet/Wallet";
import Notification from "page-sections/profiles/profile/notification/Notification";
import Billing from "page-sections/profiles/profile/billing/Billing";
import WhiteListAddress from "page-sections/profiles/profile/whitelistAddress/WhiteListAddress";
import TradeVolumeTransaction from "page-sections/profiles/profile/TradingVolumeTransactions/TradeVolumeTransaction";
import Setting from "page-sections/profiles/profile/setting/Setting";
import PaxfulAccountsService from "services/PaxfulAccountsService";
import VendorInviteService from "services/VendorInviteService";
import TradePartner from "page-sections/profiles/profile/TradePartners/TradePartner";

export const UserContext = createContext({
  userdata: null,
  team: null,
  tabValue: 1,
  trades: [],
  transactions: [],
  allOffers: [],
  bcTransactions: [],
  option: "all",
  notification: {},
  account: "active",
  tradeNumPage: 0,
  tradeVolume: null,
  activeAccount: "",
  activeAccountID: "",
  userAccounts: [],
  OfferCount: 0,
  invitationHistory: null,
  tradePartners: [],
  setTabValue: () => {},
  getUser: () => {},
  setTrades: (trades) => {},
  setTrasnactions: () => {},
  setoffers: () => {},
  setUserData: () => {},
  setBcTrasnactions: () => {},
  setOption: () => {},
  setNotification: () => {},
  getUserTeamMember: () => {},
  setAccount: () => {},
  setTradeNumPage: () => {},
  setTradeVolume: () => {},
  setActiveAccount: () => {},
  setActiveAccountID: () => {},
  setUserAccounts: () => {},
  setInvitationHistory: () => {},
  getUserPendingHistory: () => {},
  setTradePartners: () => {},
});

const ViewUser = () => {
  let location = useLocation();

  // const query = new URLSearchParams(location.search);
  // let trade_hash = query.get("trade");

  const navigate = useNavigate();
  const [trade_hash, setTrade_hash] = useState();
  const [isLoading, setLoading] = useState();
  const [tabValue, setTabValue] = useState("1");
  const [userdata, setUserData] = useState(null);
  const [trades, setTrades] = useState([]);
  const [transactions, setTrasnactions] = useState([]);
  const [allOffers, setoffers] = useState([]);
  const [selectedTrade, setSelectedTrade] = useState(null);
  const [value, setValue] = useState(0);
  const [paxfulTransactions, setPaxfulTransactions] = useState([]);
  const [bcTransactions, setBcTrasnactions] = useState([]);
  const [option, setOption] = useState("all");
  const [team, setTeam] = useState(null);
  const [tradeVolume, setTradeVolume] = useState(null);
  // select account for automations and others
  const [activeAccount, setActiveAccount] = useState(null);
  const [activeAccountID, setActiveAccountID] = useState(null);
  const [OfferCount, setOfferCount] = useState(0);
  const [invitationHistory, setInvitationHistory] = useState(null);
  const [tradePartners, setTradePartners] = useState(null);

  const [notification, setNotification] = useState({
    data: [],
    num_pages: 1,
  });
  const [account, setAccount] = useState("active");
  const [tradeNumPage, setTradeNumPage] = useState(0);
  // only for active accounts
  const [userAccounts, setUserAccounts] = useState([]);

  console.log("location", location);

  // get user id
  const { id, user_id } = useParams();

  // get Single user api
  const getUser = async (account = "active") => {
    console.log("api run");
    setLoading(true);
    const user = await UserService.getUser(id, value, account);
    setUserData(user);
    getUserPendingHistory(user?._id);

    let activeAcc = await getActiveUserPaxfulAccounts(user?._id);
    console.log("activeAcc", activeAcc);

    if (activeAcc.length > 0) {
      setActiveAccount(activeAcc[0]);
      setActiveAccountID(activeAcc[0]._id);
    }

    getOffer(user?._id, "all", "active", "");

    // if we want to take offers according to account so uncomment this code
    // getOffer(user?._id, user?.paxfullAccounts[0]._id, "active", "");

    const query = new URLSearchParams(location.search);
    const trade_hash = query.get("trade");
    if (trade_hash) {
      // console.log("tradhash", trade_hash);

      tabchange("2");
      handleTradeSelect(trade_hash);
    }
    setLoading(false);
  };

  const getUserTeamMember = async (id, type = 0) => {
    setLoading(true);
    const response = await UserService.getUserTeam(id, type);
    setTeam(response.data);
    setLoading(false);
  };

  const getUserPendingHistory = async (id) => {
    setLoading(true);
    const response = await VendorInviteService.getInvitationHistory(id);
    if (response.status) {
      setInvitationHistory(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    let query = new URLSearchParams(location.search);
    let trade_hash = query.get("trade");
    setTrade_hash(trade_hash);
    getUser();
  }, []);

  useEffect(() => {
    if (activeAccount) {
      // if we want to take offers according to account so uncomment this code
      getOffer(userdata?._id, "all", "active", "");
    }
  }, [activeAccount]);

  useEffect(() => {
    if (value === 1) {
      getUserPendingHistory(userdata?._id);
    } else {
      getUserTeamMember(id, value);
    }
  }, [value]);

  // Tab selection
  useEffect(() => {
    return () => {
      setValue(0);
    };
  }, [tabValue]);

  const _handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabChange = (_, value) => {
    console.log("changed");
    navigate(`/dashboard/users/${id}`, { replace: true });
    setTrade_hash(null);
    setTabValue(value);
    setSelectedTrade(null);
    setOption("all");
  };

  // Tab Change Via Button
  const tabchange = (value) => {
    setTabValue(value);
  };

  const handleTradeSelect = async (trade_hash) => {
    const user_trade = await TradeServices.getTrade(trade_hash, id);
    setSelectedTrade(user_trade);
  };

  const openTradeFromTab = (trade_hash) => {
    handleTradeSelect(trade_hash);
    tabchange("2");
  };

  // Back button
  const handleBackButton = () => {
    navigate(`/dashboard/users/${id}`, { replace: true });
    setSelectedTrade(null);
  };

  const openBlockTransactions = (blockchain) => {
    setOption(blockchain);
    setTabValue("13");
  };

  // Gett All User's Offers
  const getOffer = async (
    id,
    account_id = "all",
    type = "active",
    search = "",
    offerType = ""
  ) => {
    // setisLoading(true);
    const offers = await OfferService.getOffers(
      id,
      account_id,
      type,
      search,
      offerType
    );
    setoffers(offers.data);
    setOfferCount(offers.count);
  };

  const getActiveUserPaxfulAccounts = async (id) => {
    const accounts = await PaxfulAccountsService.getUserPaxfulAccounts(id, 1);

    setUserAccounts(accounts.records);
    return accounts.records;
  };

  console.log("trade_hash", trade_hash);

  useEffect(() => {
    window.scrollTo(0, 800);

    if (location?.state?.id) {
      window.scrollTo(0, 200);
    }
  }, [location?.state?.id]);
  // useEffect(() => {
  //   window.scrollTo(0, 100);
  // }, []);

  return (
    <Box pt={2} pb={4}>
      <UserContext.Provider
        value={{
          value,
          userdata,
          trades,
          transactions,
          allOffers,
          paxfulTransactions,
          bcTransactions,
          option,
          tabValue,
          team,
          notification,
          account,
          tradeNumPage,
          tradeVolume,
          activeAccount,
          activeAccountID,
          userAccounts,
          OfferCount,
          invitationHistory,
          tradePartners,

          getUser,
          setTrades,
          setTrasnactions,
          setoffers,
          setUserData,
          setPaxfulTransactions,
          setBcTrasnactions,
          setOption,
          setTabValue,
          setNotification,
          getUserTeamMember,
          setAccount,
          setTradeNumPage,
          setTradeVolume,
          setActiveAccount,
          setActiveAccountID,
          setUserAccounts,
          setInvitationHistory,
          getUserPendingHistory,
          setTradePartners,
        }}
      >
        <TabContext value={tabValue}>
          <Layout handleTabList={handleTabChange} tabValue={tabValue}>
            <TabPanel value="1">
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "20vh",
                  }}
                >
                  {" "}
                  <CircularProgress />
                </Box>
              ) : (
                !trade_hash &&
                userdata && (
                  <Overview
                    tabchange={tabchange}
                    _handleChange={_handleChange}
                    userdata={userdata}
                    anotherMember={
                      location?.state?.id ? location?.state?.id : null
                    }
                  />
                )
              )}
            </TabPanel>

            <TabPanel value="2">
              {selectedTrade ? (
                <View trade={selectedTrade} onBack={handleBackButton} />
              ) : (
                <Trades onSelect={handleTradeSelect} />
              )}
            </TabPanel>
            {/* <TabPanel value="16">
              <Billing />
            </TabPanel> */}

            <TabPanel value="3">
              {/* <Campaigns /> */}
              <PaxfulTransactions
                getUser={getUser}
                openTradeFromTab={openTradeFromTab}
              />
            </TabPanel>

            <TabPanel value="4">
              {/* <Documents />
               */}
              {allOffers && <Offers getOffer={getOffer} />}
              {/* comment */}
            </TabPanel>

            {/* <TabPanel value="5">
              <Connections />
            </TabPanel> */}

            {/* <TabPanel value="6">
              <Activity />
            </TabPanel> */}
            <TabPanel value="7">
              <UserWebhook />
            </TabPanel>
            <TabPanel value="8">
              <UserActivityLog />
            </TabPanel>
            <TabPanel value="9">
              <UserPaxfulApiLog />
            </TabPanel>
            <TabPanel value="10">
              <UserHiringRequest />
            </TabPanel>
            <TabPanel value="11">
              <Transaction />
            </TabPanel>
            <TabPanel value="12">
              <Wallet openBlockTransactions={openBlockTransactions} />
            </TabPanel>

            <TabPanel value="13">
              <BlockchainTransaction />
            </TabPanel>
            <TabPanel value="14">
              <Wallet2 />
            </TabPanel>
            <TabPanel value="15">
              <Notification />
            </TabPanel>
            <TabPanel value="17">
              <WhiteListAddress />
            </TabPanel>
            <TabPanel value="18">
              <TradeVolumeTransaction onSelect={openTradeFromTab} />
            </TabPanel>
            <TabPanel value="19">
              {allOffers && <Setting getOffer={getOffer} />}
            </TabPanel>
            <TabPanel value="20">
              <TradePartner onSelect={openTradeFromTab} />
            </TabPanel>
          </Layout>
        </TabContext>
      </UserContext.Provider>
    </Box>
  );
};

export default ViewUser;
