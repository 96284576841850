import { alpha, styled } from "@mui/material";
import { useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
const StyledScrollBar = styled(SimpleBar)(({ theme }) => ({
  maxHeight: "100%",
  "& .simplebar-scrollbar": {
    "&:before": {
      backgroundColor: alpha(theme.palette.grey[400], 0.6),
    },
    "&.simplebar-visible:before": {
      opacity: 1,
    },
  },
  "& .simplebar-track.simplebar-vertical": {
    width: 9,
  },
  "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
    height: 6,
  },
  "& .simplebar-mask": {
    zIndex: "inherit",
  },
})); // props type

const Scrollbar = ({ children, sx, scrollToBottom = false, ...props }) => {
  const ref = useRef();

  useEffect(() => {
    if (scrollToBottom && ref.current) {
      ref.current.contentWrapperEl.scrollTo({
        top: ref.current.contentWrapperEl.scrollHeight,
      });
    }
  }, [ref.current]);

  return (
    <StyledScrollBar ref={ref} sx={sx} {...props}>
      {children}
    </StyledScrollBar>
  );
};

export default Scrollbar;
