import { Edit } from "@mui/icons-material";
import { IconButton, styled, Button, Box, Icon } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import FlexBox from "components/flexbox/FlexBox";
import { Link } from "react-router-dom";
import { Tiny } from "components/Typography";
import { ArrowOutward, ArrowRight } from "@mui/icons-material";

import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import TradeStatus from "components/shared/TradeStatus";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import BuyerProfilePic from "components/shared/BuyerProfilePic";
import PaxfulAccount from "components/shared/PaxfulAccount";
import { FlagAndPlatform } from "page-sections/profiles/profile/Trades/PartnerDetail";
import UserSymbol from "components/shared/UserSymbol";
import OfferType from "components/shared/OfferType";
import { StatusBadge } from "page-sections/hiring-request/ColumnShape";
import { formatCurrency } from "utils/currencies";
import CryptoImg from "components/shared/CryptoImg";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));

const TradeVolumeColumnShape = (getCreditDescription, getDebitDescription) => {
  return [
    {
      Header: "DATE",
      accessor: "createdAt",
      Cell: ({ value }) => {
        return (
          <Box
            sx={{
              minWidth: "150px",
            }}
          >
            <Paragraph fontSize={13} color="text.secondary">
              <FormattedDateTime date={value} />
            </Paragraph>
            <Small
              sx={{
                width: 100,
                fontSize: 10,
                color: "#fff",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor: "lightgrey",
                color: "black",
              }}
            >
              <FromNow date={value} />
            </Small>
          </Box>
        );
      },
    },
    {
      Header: () => <Box sx={{ marginLeft: "12px" }}>TRANSACTION ID</Box>,
      accessor: "txn_id",
      Cell: ({ value }) => {
        return (
          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#AA336A",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: "grey.100",
            }}
          >
            {value}
          </Small>
        );
      },
    },
    {
      Header: () => <Box sx={{ marginLeft: "12px" }}>ACCOUNT</Box>,
      accessor: "account",
      Cell: ({ row }) => {
        return (
          <>
            {row.original.txn_type == "credit" && !row.original.trade && (
              <>
                <FlexBox className="d-flex">
                  <img
                    alt="Logo"
                    src="/media/logos/logo-minimize.svg"
                    height={"20px"}
                    className="h-20px"
                  />
                  <Small ml={0.5} className="ms-2 text-gray-800">
                    Trading Volume
                  </Small>
                </FlexBox>
              </>
            )}

            {row.original &&
              row.original?.trade &&
              row.original?.trade.account && (
                <FlexBox justifyContent="start" alignItems="center">
                  <PaxfulProfilePic
                    size={30}
                    account={row.original?.trade.account}
                  />
                  {row.original?.trade.account.paxful_username && (
                    <>
                      <Tiny fontSize={12} fontWeight={500}>
                        <a
                          style={{
                            marginLeft: "3px",
                          }}
                          href={
                            `https://${row.original?.trade.account.platform}.com/user/` +
                            row.original?.trade.account.paxful_username
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.original?.trade.account.paxful_username}
                          {/* <ArrowOutward
                            sx={{
                              fontSize: 14,
                              marginLeft: "1px",
                            }}
                          /> */}
                        </a>
                      </Tiny>
                      <Box ml={1}>
                        <Platform
                          size={20}
                          platform={row.original?.trade.account.platform}
                        />
                      </Box>
                    </>
                  )}
                </FlexBox>
              )}
          </>
        );
      },
    },
    {
      Header: () => <Box sx={{ marginLeft: "12px" }}>DESCRIPTION</Box>,
      accessor: "DESCRIPTION",
      Cell: ({ row }) => {
        const { txn_type } = row.original;
        return (
          <Span fontSize={13} color="text.secondary">
            {txn_type == "credit"
              ? getCreditDescription(row.original)
              : getDebitDescription(row.original)}
          </Span>
        );
      },
    },
    {
      Header: "",
      accessor: "amounsjsjbd",
      Cell: ({ row }) => {
        const { fiat_amount, txn_type } = row.original;
        return (
          <>
            {txn_type === "credit" ? (
              <Small
                sx={{
                  backgroundColor: "#27CE88",
                  padding: "1px 5px",
                  borderRadius: "4px",
                }}
                className="badge badge-light-success fs-7 fw-bolder"
              >
                +
              </Small>
            ) : (
              <Small
                sx={{
                  backgroundColor: "#FF316F",
                  padding: "1px 5px",
                  borderRadius: "4px",
                }}
                className="badge badge-light-danger fs-7 fw-bolder"
              >
                -
              </Small>
            )}
          </>
        );
      },
    },
    {
      Header: "AMOUNT",
      accessor: "amount",
      Cell: ({ row }) => {
        const { fiat_amount, txn_type } = row.original;
        return (
          <Paragraph
            fontWeight={800}
            fontSize={13}
            color={txn_type === "credit" ? "success.main" : "error.main"}
            cursor="default"
          >
            {formatCurrency(fiat_amount)}
          </Paragraph>
        );
      },
    },
    {
      Header: "TRADE VOLUME",
      accessor: "dfdf",
      Cell: ({ row }) => {
        const { fiat_balance, fee_percentage } = row.original;
        return (
          <Paragraph fontSize={13} color="text.secondary">
            {formatCurrency(fiat_balance / fee_percentage)}
          </Paragraph>
        );
      },
    },
  ];
};
export default TradeVolumeColumnShape;
