import { ArrowOutward, ArrowRightAlt } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  FormControl,
  Icon,
  MenuItem,
  Select,
  Stack,
  styled,
  Table,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AppPagination from "components/AppPagination";
import FlexBetween from "components/flexbox/FlexBetween";
import Scrollbar from "components/ScrollBar";
import NoResultFound from "components/shared/NoResultFound";
import { Paragraph, Span } from "components/Typography";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";

import {
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { arrayIsEmpty } from "utils/utils";
import SearchInput from "components/input-fields/SearchInput";
import FlexBox from "components/flexbox/FlexBox";
import PaginanationAndLimit from "components/shared/PaginanationAndLimit";
import TradeVolumeColumnShape from "./TradeVolumeColumnShape";
import { formatCryptoDecimal, formatCurrency } from "utils/currencies";
import CryptoImg from "components/shared/CryptoImg";
import CryptoSymbol from "components/shared/CryptoSymbol";
import cryptoAssets from "data/supportedAssets";
import CreatedBy from "components/shared/CreatedBy";
// import ViewActivityModal from "./view";

const theme = createMuiTheme({
  overrides: {
    MuiPaginationItem: {
      page: {
        "&.Mui-selected": {
          fontWeight: "bold",
        },
      },
    },
  },
});
// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 10px",
  color: theme.palette.text.primary,
}));

export const getCreditDescription = (transaction) => {
  const { assetId } = transaction;

  const asset = cryptoAssets.find(({ assetId: id }) => id == assetId);

  if (transaction.blockchain_txn) {
    let desc = "from Venva Wallet";
    switch (transaction.blockchain_txn.network) {
      case "lightning":
        desc = "via Lightning Network";
        break;
      case "paxfulpay":
        desc = "using Paxful Pay";
        break;
    }
    return (
      <>
        <FlexBox sx={{ alignItems: "center" }}>
          Credited with{" "}
          <Span mx={0.5}>
            {" "}
            <CryptoSymbol
              id={transaction.blockchain_txn.assetId}
              size={18}
            />{" "}
          </Span>
          {formatCryptoDecimal(
            transaction.blockchain_txn.crypto_amount,
            asset?.decimals
          )}{" "}
          {transaction.blockchain_txn.crypto_symbol} (
          {formatCurrency(transaction.blockchain_txn.fiat_amount)})
        </FlexBox>
        {desc}
        {transaction.created_by && (
          <Box className="mt-0 txn-created-by d-inline-block">
            <CreatedBy user={transaction.created_by} />
          </Box>
        )}
      </>
    );
  }

  if (transaction.stripe_txn) {
    return (
      <>
        Credited with {formatCurrency(transaction.fiat_amount)} Credit Card
        Payment
        {transaction.created_by && (
          <Box className="mt-0 txn-created-by d-inline-block">
            <CreatedBy user={transaction.created_by} />
          </Box>
        )}
      </>
    );
  }
  return transaction.description;
};

const TradeVolumelist = ({
  records,
  num_pages,
  pageNum,
  onPageChange,
  currentPage,
  handleLimitChange,
  limit,
  onSelect,
}) => {
  const getDebitDescription = (transaction) => {
    if (transaction.trade) {
      return (
        <>
          Fee Paid ({transaction.fee_percentage * 100}%) for&nbsp;
          {transaction.trade.trade.fiat_amount_requested}&nbsp;
          {transaction.trade.trade.fiat_currency_code}&nbsp;
          {transaction.trade.trade.payment_method_name} trade{" "}
          <Paragraph
            sx={{
              cursor: "pointer",
            }}
            color="primary.main"
            onClick={() => onSelect(transaction.trade.paxful_trade_hash)}
          >
            {transaction.trade.paxful_trade_hash}
            <span class="transaction-icon svg-icon svg-icon-muted svg-icon-3">
              <svg
                width="20"
                height="20"
                viewBox="0 -5 22 22"
                fill="none"
                // xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="16.9497"
                  y="8.46448"
                  width="13"
                  height="2"
                  rx="1"
                  transform="rotate(135 16.9497 8.46448)"
                  fill="currentColor"
                ></rect>
                <path
                  d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </Paragraph>{" "}
        </>
      );
    }
    return transaction.description;
  };
  const tableData = useMemo(() => records, [records]);
  const columns = useMemo(
    () => TradeVolumeColumnShape(getCreditDescription, getDebitDescription),
    []
  );

  const [filterInput, setFilterInput] = useState("");

  const defaultColumn = useMemo(
    () => ({
      Filter: () => null, // Disable individual column filters
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    setPageSize,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: tableData, //trade history
      pageCount: num_pages,
      manualPagination: true,
      defaultColumn,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  ); // handle pagination
  console.log("pageCount", pageCount);
  // console.log(pageSize);
  // const handleChange = () => gotoPage(invoiceList.length);
  const handleChange = (_, currentPageNo) => onPageChange(currentPageNo);

  // Save the state for open and close the model
  const [openModal, setOpenModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleViewButton = (trade) => {
    setOpenModal(true);
    setSelectedRecord(trade);
  };

  const { globalFilter } = state;

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilterInput(value);
    setGlobalFilter(value);
  };
  // console.log(tableData);
  const navigate = useNavigate();
  return (
    // <ThemeProvider theme={theme}>
    <Box>
      {/* <SearchInput
        placeholder="Search"
        type="text"
        value={filterInput}
        onChange={handleFilterChange}
      /> */}
      <Scrollbar autoHide={false}>
        <Table
          {...getTableProps()}
          sx={{
            borderSpacing: "0 1rem",
            borderCollapse: "separate",
            minWidth: 900,
          }}
        >
          <TableHead>
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <HeadTableCell
                    key={index}
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </HeadTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {arrayIsEmpty(tableData) ? (
              <StyledTableCell colSpan={7} align="center">
                <NoResultFound />
              </StyledTableCell>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <StyledTableRow
                    key={index}
                    {...row.getRowProps()}
                    // sx={{
                    //   cursor: "pointer",
                    // }}
                    // Click to open a Model
                    // onClick={() => {console.log("Click On Edit")}}
                    onClick={() => handleViewButton(row.original)}
                  >
                    {row.cells.map((cell, index) => (
                      <BodyTableCell key={index} {...cell.getCellProps()}>
                        {cell.render("Cell", { onClick: onSelect })}
                      </BodyTableCell>
                    ))}
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Scrollbar>
      {/* Model Open And Close */}
      {/* {selectedRecord && (
        <ViewActivityModal
          data={selectedRecord}
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )} */}
      {/* 
      <FlexBetween>
        <Paragraph color="text.secondary" fontSize={13}>
          Showing 1-12 of {pageCount * state.pageSize} result
        </Paragraph>

        <ButtonBase
          onClick={handleChange}
          disableRipple
          sx={{
            fontSize: 13,
            fontWeight: 600,
          }}
        >
          View All
          <ArrowRightAlt
            sx={{
              marginLeft: 0.5,
            }}
          />
        </ButtonBase>
      </FlexBetween> */}

      <PaginanationAndLimit
        tableData={tableData}
        handleChange={handleChange}
        count={pageOptions.length}
        currentPage={currentPage}
        handleLimitChange={handleLimitChange}
        limit={limit}
      />
    </Box>
    // </ThemeProvider>
  );
};

export default TradeVolumelist;
