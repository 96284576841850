import React from "react";
import PaxfulProfilePic from "./PaxfulProfilePic";
import { formatCurrency } from "utils/currencies";
import ATooltip from "./ATooltip";

function FormattedBalance({
  code,
  amount,
  offer_type,
  fs,
  status,
  isPartner,
  partner,
  user,
}) {
  // const getAmountStatus = (offer_type, status) => {
  //   switch (offer_type) {
  //     case "buy":
  //       return status == "new"
  //         ? `${user.username} are paying`
  //         : `${user.username}  paid`;
  //     case "sell":
  //       return status == "new"
  //         ? `${user.username} are receiving`
  //         : `${user.username}  received`;
  //   }
  // };

  const getAmountStatus = (offer_type, status) => {
    switch (offer_type) {
      case "buy":
        return status === "cancelled"
          ? `${user.username} would have received `
          : status === "released"
          ? `${user.username} paid`
          : `${user.username} is paying`;
      case "sell":
        return status === "cancelled"
          ? `${user.username} would have received`
          : status === "released"
          ? `${user.username} received`
          : `${user.username}  is receiving`;
      default:
        return "";
    }
  };

  const getPartnerAmountStatus = (offer_type) => {
    switch (offer_type) {
      case "buy":
        return (
          <>
            <PaxfulProfilePic
              account={partner}
              size={15}
              buyer
              className={"me-1"}
            />
            {partner.username} received
          </>
        );

      case "sell":
        return (
          <>
            <PaxfulProfilePic
              account={partner}
              size={15}
              buyer
              className={"me-1"}
            />
            {partner.username} paid
          </>
        );
    }
  };

  //offer_type == "buy" ? "paying" : "receiving"

  return (
    <>
      <ATooltip
        content={
          <>
            {!isPartner
              ? getAmountStatus(offer_type, status)
              : getPartnerAmountStatus(offer_type)}{" "}
            {formatCurrency(amount, code)} {code}
          </>
        }
      >
        <span className={`fs-${fs}`}>
          {formatCurrency(amount, code)} {code}
        </span>
      </ATooltip>
    </>
  );
}

FormattedBalance.defaultProps = {
  fs: "6",
  isPartner: false,
};

export default FormattedBalance;
