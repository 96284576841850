import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  styled,
  Table,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Scrollbar from "components/ScrollBar";
import NoResultFound from "components/shared/NoResultFound";
import { useNavigate } from "react-router-dom";
import { arrayIsEmpty } from "utils/utils";
import RvaColumnShape from "./RealVaColumnShape";
import RVAService from "services/RealVAService";

import {
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import PaginanationAndLimit from "components/shared/PaginanationAndLimit";
import AddRealVaModal from "./AddRealVaModal";
import { H5, H6 } from "components/Typography";

const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 10px",
  color: theme.palette.text.primary,
}));

const RealVaList = ({
  data,
  num_pages,
  onPageChange,
  currentPage,
  handleLimitChange,
  limit,
  getRVA,
}) => {
  const [rvaData, setRvaData] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const onCellClick = (value) => {
    setRvaData(value);
  };
  //on edit

  const onEdit = (rowData) => {
    setRvaData(rowData);
    setOpenModal(true);
  };

  // For Delete User
  const [userToDelete, setUserToDelete] = useState(null);
  const [user, setUser] = useState(null);

  const handleCancelConfirm = () => {
    setOpen(false);
    setUserToDelete(null);
  };
  const confirmDeleteUser = ({ _id }) => {
    setOpen(true);
    setUserToDelete(_id);
  };

  console.log("userToDelete", userToDelete);

  const handleDeleteUser = async () => {
    await RVAService.deleteRVA(userToDelete);
    setOpen(false);
    getRVA(1, 50, "active", "");
  };

  const tableData = useMemo(() => data, [data]);
  const columns = useMemo(
    () => RvaColumnShape(onCellClick, onEdit, confirmDeleteUser),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: tableData, //UserRequestData
      pageCount: num_pages,
      manualPagination: true,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  ); // handle pagination
  const handleChange = (_, currentPageNo) => onPageChange(currentPageNo);
  const navigate = useNavigate();

  return (
    <Box>
      <Scrollbar autoHide={false}>
        <Table
          {...getTableProps()}
          sx={{
            borderSpacing: "0 1rem",
            borderCollapse: "separate",
            minWidth: 900,
          }}
        >
          <TableHead>
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <HeadTableCell
                    key={index}
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </HeadTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {arrayIsEmpty(tableData) ? (
              <StyledTableCell colSpan={7} align="center">
                <NoResultFound />
              </StyledTableCell>
            ) : (
              page.map((row, index) => {
                prepareRow(row);

                return (
                  <StyledTableRow
                    key={index}
                    {...row.getRowProps()}
                    // sx={{
                    //   cursor: "pointer",
                    // }}
                  >
                    {row.cells.map((cell, index) => (
                      <BodyTableCell key={index} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </BodyTableCell>
                    ))}
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
          <Dialog
            open={open}
            //  onClose={handleClose}
          >
            <DialogTitle
              style={{
                fontSize: "0.925rem",
              }}
            >
              <H5>Confirm Delete</H5>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{
                  fontSize: "0.925rem",
                }}
              >
                <H6> will delete this user permanently. Are you sure?</H6>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelConfirm}>Cancel</Button>
              <Button onClick={() => handleDeleteUser()}>Delete</Button>
            </DialogActions>
          </Dialog>
        </Table>
        <AddRealVaModal
          onSuccess={() => getRVA(1, "")}
          data={rvaData}
          open={openModal}
          edit={true}
          onClose={() => setOpenModal(false)}
        />
      </Scrollbar>
      <PaginanationAndLimit
        tableData={tableData}
        handleChange={handleChange}
        count={pageOptions.length}
        currentPage={currentPage}
        handleLimitChange={handleLimitChange}
        limit={limit}
      />
    </Box>
  );
};

export default RealVaList;
