import { ArrowRightAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  styled,
  Table,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AppPagination from "components/AppPagination";
import FlexBetween from "components/flexbox/FlexBetween";
import Scrollbar from "components/ScrollBar";
import NoResultFound from "components/shared/NoResultFound";
import { H5, H6, Paragraph } from "components/Typography";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "components/shared/DeleteDialog";

import {
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { arrayIsEmpty } from "utils/utils";

import UserRequestApi from "services/userRequestService";
import columnShape from "./columnShape";
import VendorInviteService from "services/VendorInviteService";
import UserService from "services/UserService";
// import ViewActivityModal from "./view";

// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 10px",
  color: theme.palette.text.primary,
}));

const PendingList = ({
  data,
  num_pages,
  limit,
  onPageChange,
  currentPage,
  getPendingInvite,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState({
    invitedVia: null,
    docId: null,
  });
  console.log("open", open);

  // console.log("records", records);

  const onCellClick = (id, invited_via) => {
    console.log("id, invited_via", id, invited_via);
    console.log(`Cell clicked: ${id} , invited_via: ${invited_via}`);
    setOpen(true);
    setDeleteDoc({
      invitedVia: invited_via,
      docId: id,
    });
  };
  const handleCancelConfirm = () => {
    setOpen(false);
    setDeleteDoc({ invitedVia: null, docId: null });
  };
  // const resp = await UserService.DeleteUser(deleteDoc);
  const handleDeletePendingInvite = async () => {
    console.log("deleteDoc.invitedVia", deleteDoc.invitedVia);
    if (deleteDoc.invitedVia == "link") {
      console.log("hello");
      await VendorInviteService.delPendingInvitation(deleteDoc.docId);
    } else {
      console.log("fijsdbfksbfkjbskb");

      await UserService.DeleteUser(deleteDoc.docId);
    }
    setOpen(false);
    getPendingInvite(currentPage, "");
  };

  const tableData = useMemo(() => data, [data]);
  const columns = useMemo(() => columnShape(onCellClick), []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: tableData, //UserRequestData
      pageCount: num_pages,
      manualPagination: true,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  ); // handle pagination

  const handleChange = (_, currentPageNo) => onPageChange(currentPageNo);

  // Save the state for open and close the model
  const [openModal, setOpenModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleViewButton = (webhook) => {
    setOpenModal(true);
    setSelectedRecord(webhook);
  };

  return (
    <Box>
      <Scrollbar autoHide={false}>
        <Table
          {...getTableProps()}
          sx={{
            borderSpacing: "0 1rem",
            borderCollapse: "separate",
            minWidth: 900,
          }}
        >
          <TableHead>
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <HeadTableCell
                    key={index}
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </HeadTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {arrayIsEmpty(tableData) ? (
              <StyledTableCell colSpan={7} align="center">
                <NoResultFound />
              </StyledTableCell>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <StyledTableRow
                    key={index}
                    {...row.getRowProps()}
                    // sx={{
                    //   cursor: "pointer",
                    // }}
                    onClick={() => handleViewButton(row.original)}
                  >
                    {row.cells.map((cell, index) => (
                      <BodyTableCell key={index} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </BodyTableCell>
                    ))}
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
          {/* <Dialog
            open={open}
            //  onClose={handleClose}
          >
            <DialogTitle
              style={{
                fontSize: "0.925rem",
              }}
            >
              <H5>Confirm Delete</H5>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{
                  fontSize: "0.925rem",
                }}
              >
                <H6>
                  It will delete this Invitation permanently. Are you sure?
                </H6>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelConfirm}>Cancel</Button>
              <Button onClick={() => handleDeletePendingInvite()}>
                Delete
              </Button>
            </DialogActions>
          </Dialog> */}
          <DeleteDialog
            open={open}
            title={"Confirm Delete"}
            subTitle={
              "It will delete this Invitation Pemanently. Are you sure?"
            }
            handleCancel={handleCancelConfirm}
            handleConfirm={() => handleDeletePendingInvite()}
          />
        </Table>
      </Scrollbar>
      <Stack alignItems="center" marginY={0}>
        {tableData.length > 0 && (
          <AppPagination
            shape="rounded"
            onChange={handleChange}
            count={pageOptions.length}
            page={currentPage}
          />
        )}
      </Stack>
    </Box>
  );
};

export default PendingList;
