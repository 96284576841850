import { getToken } from "helpers/auth-va";
import duotone from "icons/duotone";

let navigations = [
  {
    type: "label",
    label: "Dashboard",
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: duotone.LayerGroup,
  },
  {
    name: "Vendors",
    path: "/dashboard/users",
    icon: duotone.Vendor,
  },
  {
    name: "Active Trades",
    path: "/dashboard/active-trade",
    icon: duotone.Active,
  },
  {
    name: "Trade History",
    path: "/dashboard/trade-history",
    icon: duotone.History,
  },
  {
    name: "User Master List",
    path: "/dashboard/user-master-list",
    icon: duotone.Portal,
  },
  {
    name: "Hire Request",
    path: "/dashboard/hire-requests",
    icon: duotone.UserList,
    extraInfo: true,
  },
  {
    name: "Virtual Assistant",
    path: "/dashboard/hire-virtual-assistant",
    icon: duotone.Va,
  },
  {
    name: "VA Accounts",
    path: "/dashboard/virtual-accounts",
    icon: duotone.Va,
  },
  {
    name: "User Request",
    path: "/dashboard/user-request",
    icon: duotone.UserRequest,
    extraInfo: true,
  },
  // {
  //   name: "Abandoned User",
  //   path: "/dashboard/abandoned-user",
  //   icon: duotone.UserRequest,
  //   extraInfo: true,
  // },
  {
    name: "Management",
    path: "/dashboard/management",
    icon: duotone.ProjectChart,
  },
  {
    name: "P2P Webhooks",
    path: "/dashboard/webhooks",
    icon: duotone.TodoList,
  },
  {
    name: "P2P Accounts",
    path: "/dashboard/vendor-accounts",
    icon: duotone.UserAccount,
  },
  {
    name: "Trade Partners",
    path: "/dashboard/tradepartners",
    icon: duotone.UserList,
  },

  // {
  //   name: "Real VA",
  //   path: "/dashboard/real-va",
  //   icon: duotone.Portal,
  // },

  // {
  //   name: "VirtualAssistence",
  //   path: "/dashboard/virtualassistence",
  //   icon: duotone.VirtualAssistence,
  // },
  {
    name: "API Log",
    path: "/dashboard/api-log",
    icon: duotone.Log,
  },
  {
    name: "Activity Log",
    path: "/dashboard/activity-log",
    icon: duotone.DataTable,
  },
  {
    name: "Webhook Log",
    path: "/dashboard/webhook-log",
    icon: duotone.DataTable,
  },
  // {
  //   name: "Prepaid2Coins",
  //   path: "/dashboard/prepaid2Coin",
  //   icon: duotone.Card,
  // },
  // {
  //   name: "VA Portal",
  //   path: "/dashboard/va-agencies",
  //   icon: duotone.Portal,
  // },
  {
    name: "Venva Revenue",
    path: "/dashboard/atlantis-revenue",
    icon: duotone.Portal,
  },
  // {
  //   name: "Fake VAs",
  //   path: "/dashboard/virtual-assistant",
  //   icon: duotone.Va,
  // },
  ,
];

// if (getToken()) {
//   navigations = navigations.concat([
//     {
//       name: "VA Agencies",
//       path: "/dashboard/virtual-assistant",
//       icon: duotone.RecordVoiceOverIcon,
//     },
//   ]);
// }

export default navigations;
