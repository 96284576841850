import { Box, Button, Chip, IconButton, Stack, Tooltip } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { H6, Paragraph, Small, Span, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, ContentCopy } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import styled from "@emotion/styled";
import CustomChip from "components/shared/CustomChip";
import Platform from "components/shared/Platform";
import FlexBox from "components/flexbox/FlexBox";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import notification from "helpers/notifications";
import ATooltip from "components/shared/ATooltip";
const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};
export const columnShape = (onCellClick) => [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
            cursor: "pointer",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}> DATE </Box>,
    accessor: "createdAt",
    Cell: ({ value }) => (
      <>
        <Paragraph fontSize={13} color="text.secondary">
          <FormattedDateTime date={value} />
        </Paragraph>
        <Small
          sx={{
            width: 100,
            fontSize: 10,
            color: "#fff",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor: "lightgrey",
            color: "black",
          }}
        >
          <FromNow date={value} />
        </Small>
      </>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "12px" }}>USER </Box>,
    accessor: "username",
    Cell: ({ row }) => {
      // console.log("row.original", row.original);
      const { username, platform } = row.original;
      return (
        <FlexBox alignItems={"center"}>
          <PaxfulProfilePic account={row.original.profile} buyer />

          <Box
            sx={{
              marginLeft: "10px !important",
              minWidth: "150px !important",
            }}
          >
            <Paragraph fontSize={13} color="text.secondary">
              {username}
            </Paragraph>
          </Box>
          {platform && <Platform platform={platform} size={20} />}
        </FlexBox>
      );
    },
  },
  //   {
  //     Header: "VERIFIED",
  //     accessor: "sdsdsd",
  //     Cell: ({ row }) => {
  //       const { status } = row.original.profile;
  //       return (
  //         <>
  //           {status == "active" ? (
  //             <CustomChip label={"ACTIVE"} bgColor={"success.main"} />
  //           ) : (
  //             <CustomChip label={"sd"} bgColor={"error.main"} />
  //           )}
  //         </>
  //       );
  //     },
  //   },
  // {
  //   Header: "PLATFORM",
  //   accessor: "platform",
  //   Cell: ({ value }) => (
  //     <>
  //       <FlexBox>
  //         <Paragraph mr={1} fontSize={13} color="text.secondary">
  //           {value}
  //         </Paragraph>
  //         {value && <Platform platform={value} size={20} />}
  //       </FlexBox>
  //     </>
  //   ),
  // },
  {
    Header: () => <Box sx={{ marginLeft: "8px" }}> VERIFIED </Box>,
    accessor: "profile",
    Cell: ({ value }) => {
      const { is_verified } = value;
      return (
        <>
          {is_verified ? (
            <CustomChip label={"true"} bgColor={"success.main"} />
          ) : (
            <CustomChip label={"false"} bgColor={"error.main"} />
          )}
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>JOIN DATE </Box>,
    accessor: "sasa",
    Cell: ({ row }) => {
      const { joined } = row.original.profile;
      console.log("joined", row.original.profile);
      return (
        <>
          <Paragraph mr={1} fontSize={13} color="text.secondary">
            {joined}
          </Paragraph>
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>FEEDBACK </Box>,
    accessor: "asas",
    Cell: ({ row }) => {
      const { feedback_negative, feedback_positive } = row.original.profile;
      return (
        <FlexBox
          sx={{
            alignItems: "center",
          }}
        >
          <Chip
            size="small"
            icon={<ThumbUpIcon color="success" />}
            label={feedback_positive}
          />
          <Chip
            sx={{
              marginLeft: "5px",
            }}
            size="small"
            icon={<ThumbDownIcon color="error" />}
            label={feedback_negative}
          />
        </FlexBox>
      );
    },
  },
  {
    Header: "ACTIONS",
    accessor: "actions",
    Cell: ({ row }) => {
      const { _id } = row.original;

      return (
        <>
          <StyledButton
            sx={{ alignItems: "center" }}
            onClick={() => onCellClick(_id)}
            variant="outlined"
            color="primary"
            size="small"
          >
            VIEW
          </StyledButton>
        </>
      );
    },
  },
];
export default columnShape;
