import React, { useState, useEffect, useCallback, useContext } from "react";
import _ from "underscore";
import Autosuggest from "react-autosuggest";
import { useDropzone } from "react-dropzone";
// import FilesServices from "../../services/FilesService";
// import InfoToolTip from "../widgets/InfoToolTip";
import EmojiPicker from "emoji-picker-react";
// import { Tween } from "react-gsap";
import FsLightbox from "fslightbox-react";
// import Platform from "../widgets/Platform";
import platforms from "data/platforms";
import Platform from "components/shared/Platform";
import emitter from "helpers/emitter";
import { getFileExtension } from "utils/utils";
import { COMMON_API_BASE_URL } from "config";
import ATooltip from "components/shared/ATooltip";
import notification from "helpers/notifications";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { Box, Checkbox, Input, Popover, Switch } from "@mui/material";
import SelectOffers from "./SelectOffers";
import { UserContext } from "page-sections/user-list/ViewUser";
import FilesServices from "services/FileServices";
import { Paragraph, Small, Span } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import OfferType from "components/shared/OfferType";
import { SettingsContext } from "contexts/settingsContext";
import CloseIcon from "@mui/icons-material/Close";
// import platforms from "../../data/platform";
// import PlatformLogo from "../widgets/PlatformLogo";
// import { TextareaSkeleton } from "../skeleton/AutomationsSkeleton";
import CryptoImg from "components/shared/CryptoImg";
function Textarea({
  onChange,
  enabled,
  message,
  offers,
  file,
  for_cancelled,
  onRemove,
  isEditing,
  selectedOffers,
  // allOffers,
  label,
  messageSuggestions,
  uploadImage,
  id,
  buttonLabel,
  closingMessages,
  emoji,
  kycMessage,
  valid,
  defaultMessage,
  platform,
  // activeAccount,
}) {
  const [value, setValue] = useState({
    enabled,
    message,
    offers,
    file,
  });

  const { userdata, allOffers, activeAccount } = useContext(UserContext);

  const { settings, saveSettings } = useContext(SettingsContext);

  const [showMore, setShowMore] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [themMode, setThemMode] = useState("light");
  const [toggler, setToggler] = useState(false);

  // const { images, setImages, openImage } = useContext(ImageGalleryContext);

  const handleEmojiClick = ({ emoji }) => {
    const _value = { ...value };
    _value.message = `${_value.message} ${emoji}`;
    setValue(_value);
    onChange(_value);
  };

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);

      const resp = await FilesServices.uploadFile(formData, userdata._id);
      if (resp.status == true) {
        const _value = { ...value };
        _value.file = resp.file.file_path;

        setValue(_value);
        onChange(_value);
      }
    }
    if (rejectedFiles.length > 0) {
      notification.error(
        "Invalid File",
        "Only JPG, PDF, PNG and JPEG are supported."
      );
    }
    setIsUploading(false); //uploading completed
    setDialogOpen(false);
    // UPLOADING_PROOF = false;
    //setUploadingProof(false); //set upload proof to false
  });

  const extension = value.file ? getFileExtension(value.file) : null;

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
  });

  const toggleshowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    setValue({
      enabled,
      message,
      offers,
      file,
      for_cancelled,
    });
  }, [enabled, message, offers]);

  // useEffect(() => {
  //   const _theme = document.querySelector("html").getAttribute("data-theme"); //current theme
  //   //listner for theme change event
  //   setThemMode(_theme);

  //   const listner1 = emitter.addListener("theme:mode:changed", (theme) => {
  //     setThemMode(theme);
  //   });

  //   const listener = emitter.addListener("clicked:outside:emojis", () => {
  //     setShowEmojis(false);
  //   });

  //   const listner2 = emitter.addListener("clicked:outside:popover", () => {
  //     setShowMore(false);
  //   });

  //   return () => {
  //     listener.remove();
  //     listner1.remove();
  //     listner2.remove();
  //   };
  // }, []);

  const getOfferDetails = (offer, index) => {
    const _offer = _.find(allOffers, function (_offer) {
      return (
        _offer.paxful_offer_hash == offer.value &&
        _offer.account._id == offer.account_id
      );
    });

    if (!_offer) return null;

    return (
      <>
        <Box
          my={1}
          sx={{
            alignItems: "center",
            // padding: "5px",
          }}
          key={index}
          className={`mb-${
            index < value.length - 1 ? "1" : "0"
          } py-1 px-2 rounded border d-inline-block`}
        >
          <FlexBox
            sx={{
              backgroundColor: "#f5f8fa",
              borderRadius: "5px",
              padding: "5px",
              // margin: "2px",
              alignItems: "start",
              justifyContent: "space-between",
            }}
            key={index}
            className={`mb-${
              index < value.length - 1 ? "1" : "0"
            } py-1 px-2 rounded border`}
          >
            <FlexBox>
              <Box mt={0.5}>
                {/* <PaxfulProfilePic size={20} account={_offer.account} /> */}
                <FlexBox
                  sx={{ alignItem: "center" }}
                  className="user-inner-wrap mt-1 mb-1"
                >
                  <Box
                    mx={0.5}
                    sx={{ alignItem: "center", position: "relative" }}
                  >
                    <PaxfulProfilePic
                      account={activeAccount}
                      size={20}
                      className={"ms-2"}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: -4,
                        left: 10,
                      }}
                    >
                      <Platform
                        platform={activeAccount?.platform}
                        size={15}
                        className={"w-20px me-1"}
                      />
                    </Box>
                  </Box>
                </FlexBox>
              </Box>
              <Box ml={1}>
                <FlexBox
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                  className="d-inline-flex align-items-center fw-bold"
                >
                  <Small color="black">
                    {_offer.offer.payment_method_name}
                  </Small>

                  <Box ml={0.5}>
                    {/* <Platform
                      size="20"
                      platform={_offer.account.platform}
                      className={"h-20px ms-2"}
                    /> */}
                    <CryptoImg
                      currency={_offer.offer.crypto_currency}
                      size={16}
                      className={"h-20px ms-2"}
                    />
                  </Box>
                </FlexBox>
                <FlexBox sx={{ alignItems: "center" }} className="">
                  <Small mr={1} fontSize={10} color="text.secondary">
                    {_offer.paxful_offer_hash}
                  </Small>
                  <Small
                    sx={{
                      marginRight: "3px",
                      borderRadius: "4px",
                      padding: "0rem .4rem",
                      backgroundColor:
                        _offer.offer.offer_type == "buy"
                          ? "success.main"
                          : "error.main",
                    }}
                  >
                    {_offer.offer.offer_type}
                  </Small>

                  <Small fontSize={10} color="text.secondary">
                    {_offer.offer.margin}%{" "}
                  </Small>
                </FlexBox>
              </Box>
            </FlexBox>

            <Small sx={{ cursor: "pointer" }}>
              <a
                className="cursor-pointer remove-offer"
                onClick={() => {
                  removeOffer(index);
                }}
              >
                <span className="svg-icon svg-icon-4">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x="7.05025"
                      y="15.5356"
                      width="12"
                      height="2"
                      rx="1"
                      transform="rotate(-45 7.05025 15.5356)"
                      fill="currentColor"
                    />
                    <rect
                      x="8.46447"
                      y="7.05029"
                      width="12"
                      height="2"
                      rx="1"
                      transform="rotate(45 8.46447 7.05029)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>
            </Small>
          </FlexBox>
        </Box>
      </>
    );
  };

  let initial_offers = value.offers;
  let more_offers = [];
  if (value.offers.length > 3) {
    initial_offers = value.offers.slice(0, 3);
    more_offers = value.offers.slice(3);
  }

  function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  const getSuggestions = (value) => {
    const inputValue = value ? value.trim().toLowerCase() : "";
    const inputLength = inputValue.length;

    return inputLength < 5
      ? messageSuggestions
      : messageSuggestions.filter(
          (message) =>
            message.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const getSuggestionValue = (suggestion) => {
    return suggestion;
  };

  const renderSuggestion = (suggestion) => {
    return <span>{suggestion}</span>;
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const removeOffer = (index) => {
    const _value = { ...value };
    const offers = [..._value.offers].filter((offer, _index) => {
      return _index != index;
    });
    _value.offers = offers;

    setValue(_value);
    onChange(_value);
  };

  const PhotoItem = ({ image }) => (
    <span className="symbol symbol-30px cursor-pointer">
      <img src={image} />
    </span>
  );

  const handleRemoveImage = () => {
    const _value = { ...value };
    _value.file = null;

    setValue(_value);
    onChange(_value);
  };

  // useEffect(() => {
  //   const file_path = `${COMMON_API_BASE_URL}/${file}`;
  //   if (file && images.indexOf(file_path) == -1) {
  //     const _images = [...images].push(file_path);
  //     //setImages(_images)
  //   }
  // }, [file]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // document.body.style.overflow = "hidden";
  };

  const handleClose = () => {
    setAnchorEl(null);
    // document.body.style.overflow = "visible";
  };

  const _open = Boolean(anchorEl);
  const _id = _open ? "simple-popover" : undefined;
  return (
    <>
      {/* {!isEditing && !allOffers && <TextareaSkeleton />} */}
      {allOffers && (
        <div className="" {...getRootProps()}>
          {isEditing && (
            <FlexBox
              sx={{ alignItems: "center", justifyContent: "space-between" }}
              className="d-flex align-items-start justify-content-between"
            >
              <FlexBox className="d-flex">
                <Switch
                  checked={value.enabled}
                  onChange={({ target }) => {
                    let _value = { ...value };
                    _value.enabled = target.checked;
                    setValue(_value);
                    onChange(_value);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  label="iOS style"
                />
                {/* <Input
                  className="form-check-input"
                  type="checkbox"
                  checked={value.enabled}
                  onChange={({ target }) => {
                    let _value = { ...value };
                    _value.enabled = target.checked;
                    setValue(_value);
                    onChange(_value);
                  }}
                /> */}
                <div className="ms-2">
                  <div className="offer-enable-status">
                    <Small fontSize={16}>
                      {value.enabled ? "Enabled" : "Disabled"}
                    </Small>
                  </div>

                  {platform && (
                    // <FlexBox>
                    //   <Small fontSize={12} color="text.secondary">
                    //     Automated {label} is{" "}
                    //     {value.enabled ? "enabled" : "disabled"} for following{" "}
                    //   </Small>
                    //   <span
                    //     style={{
                    //       marginLeft: "5px",
                    //     }}
                    //   >
                    //     <Platform platform={platform} size={20} />
                    //   </span>

                    //   <Small fontSize={12} color="text.secondary">
                    //     {platforms[platform].title} offers
                    //   </Small>
                    // </FlexBox>
                    <FlexBox>
                      <Box
                        sx={{
                          display: "flex",
                          "@media (max-width: 1405px)": {
                            display: "block",
                          },
                        }}
                        fontSize={12}
                        color="text.secondary"
                      >
                        Automatic {label} is{" "}
                        {value.enabled ? "enabled" : "disabled"} for following{" "}
                        {/* <FlexBox>
                          <span
                            sx={{
                              marginLeft: "8px",
                              marginRight: "5px",
                              "@media (max-width: 1405px)": {
                                marginLeft: 0,
                                marginRight: "5px",
                              },
                            }}
                          >
                            <Platform platform={platform} size={15} />
                          </span>
                          {platforms[platform].title} offers
                        </FlexBox> */}
                        <Span
                          mx={0.5}
                          sx={{
                            alignItem: "center",
                            position: "relative",
                            display: "inline",
                          }}
                        >
                          <PaxfulProfilePic
                            account={activeAccount}
                            size={20}
                            className={"ms-2"}
                          />
                          <Span
                            sx={{
                              position: "absolute",
                              top: -8,
                              left: 10,
                            }}
                          >
                            <Platform
                              platform={activeAccount?.platform}
                              size={15}
                              className={"w-20px me-1"}
                            />
                          </Span>
                        </Span>
                        {activeAccount?.paxful_username} offers
                      </Box>
                    </FlexBox>
                  )}
                </div>
              </FlexBox>
              <a
                onClick={onRemove}
                className="cursor-pointer remove-card text-hover-danger"
              >
                <Small
                  sx={{
                    backgroundColor: "#FFCCCB",
                    borderRadius: "5px",
                    padding: "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon color="error" sx={{ fontSize: 16 }} />
                </Small>
              </a>
            </FlexBox>
          )}

          <div className="mb-4">
            <div className="w-100">
              {!isEditing && activeAccount && (
                // <></>
                <SelectOffers
                  selected={selectedOffers}
                  onChange={(values) => {
                    let _value = { ...value };
                    _value.offers = values;
                    setValue(_value);
                    onChange(_value);
                  }}
                  isMulti={true}
                  hasOffers={value.offers.length > 0}
                  activeAccountId={activeAccount?._id}
                />
              )}
              {isEditing && (
                <div className="my-4 d--flex flex--wrap">
                  <div className="offer--tag--wrap">
                    {initial_offers?.map((offer, index) => {
                      return (
                        <div
                          key={index}
                          className="offer-tag me-1 bg-light p-10px"
                        >
                          {getOfferDetails(offer, index)}
                        </div>
                      );
                    })}
                  </div>
                  {more_offers.length > 0 && (
                    <div style={{ pointerEvents: "auto" }} className="w-100">
                      <a
                        id={`more_offers_${id}`}
                        onClick={handleClick}
                        className="open-popover cursor-pointer fw-bolder more-items-link fs-7"
                      >
                        <Small
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          +{more_offers.length} more
                        </Small>
                      </a>
                    </div>
                  )}

                  {_open && more_offers.length > 0 && (
                    <Popover
                      open={_open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      placement="top-start"
                      disablePortal={false}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}

                      // isOpen={showMore}
                      // toggle={() => {}}
                      // target={`more_offers_${id}`}
                      // placement="left"
                    >
                      <Box className="p-2">
                        <div className="widget-scroll-y ">
                          <Box
                            sx={{
                              padding: "5px",
                              // border: "1px solid lightgray",
                              // boxShadow:
                              //   "0 20px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                            }}
                          >
                            <Box
                              sx={{ maxHeight: "40vh", minWidth: "300px" }}
                              className="widget-scroll-y "
                            >
                              {more_offers?.map((offer, index) => {
                                const _offer = _.find(
                                  allOffers,
                                  function (_offer) {
                                    return (
                                      _offer.paxful_offer_hash == offer.value &&
                                      _offer.account._id == offer.account_id
                                    );
                                  }
                                );

                                return (
                                  <FlexBox
                                    sx={{
                                      border: "1px solid lightgray",
                                      borderRadius: "5px",
                                      padding: "5px",
                                      margin: "2px",
                                      alignItems: "start",
                                      justifyContent: "space-between",
                                    }}
                                    key={index}
                                    className={`mb-${
                                      index < value.length - 1 ? "1" : "0"
                                    } py-1 px-2 rounded border`}
                                  >
                                    <FlexBox>
                                      {/* <Box mt={0.5}>
                                        <PaxfulProfilePic
                                          size={20}
                                          account={_offer.account}
                                        />
                                      </Box> */}
                                      <Box mt={0.5}>
                                        <FlexBox
                                          sx={{ alignItem: "center" }}
                                          className="user-inner-wrap mt-1 mb-1"
                                        >
                                          <Box
                                            mx={0.5}
                                            sx={{
                                              alignItem: "center",
                                              position: "relative",
                                            }}
                                          >
                                            <PaxfulProfilePic
                                              account={activeAccount}
                                              size={20}
                                              className={"ms-2"}
                                            />
                                            <Box
                                              sx={{
                                                position: "absolute",
                                                top: -4,
                                                left: 10,
                                              }}
                                            >
                                              <Platform
                                                platform={
                                                  activeAccount?.platform
                                                }
                                                size={15}
                                                className={"w-20px me-1"}
                                              />
                                            </Box>
                                          </Box>
                                        </FlexBox>
                                      </Box>
                                      <Box ml={1}>
                                        <FlexBox
                                          sx={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                          }}
                                          className="d-inline-flex align-items-center fw-bold"
                                        >
                                          <Small
                                            color={
                                              settings.theme == "dark"
                                                ? " white"
                                                : "black"
                                            }
                                          >
                                            {_offer.offer.payment_method_name}
                                          </Small>
                                          {/* <Platform
                                            size="20"
                                            platform={_offer.account.platform}
                                            className={"h-20px ms-2"}
                                          /> */}
                                          <Box ml={0.5}>
                                            <CryptoImg
                                              currency={
                                                _offer.offer.crypto_currency
                                              }
                                              size={16}
                                              className={"h-20px ms-2"}
                                            />
                                          </Box>
                                        </FlexBox>
                                        <FlexBox
                                          sx={{ alignItems: "center" }}
                                          className=""
                                        >
                                          <Small
                                            mr={1}
                                            fontSize={10}
                                            color="text.secondary"
                                          >
                                            {_offer.paxful_offer_hash}
                                          </Small>

                                          <Small
                                            sx={{
                                              marginRight: "3px",
                                              borderRadius: "4px",
                                              padding: "0rem .4rem",
                                              backgroundColor:
                                                _offer.offer.offer_type == "buy"
                                                  ? "success.main"
                                                  : "error.main",
                                            }}
                                          >
                                            {_offer.offer.offer_type}
                                          </Small>

                                          <Small
                                            fontSize={10}
                                            color="text.secondary"
                                          >
                                            {_offer.offer.margin}%{" "}
                                          </Small>
                                        </FlexBox>
                                      </Box>
                                    </FlexBox>

                                    <Small sx={{ cursor: "default" }}>
                                      <a
                                        className="cursor-pointer remove-offer"
                                        // onClick={() => {
                                        //   removeOffer(index);
                                        // }}
                                      >
                                        <span className="svg-icon svg-icon-4">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              opacity="0.5"
                                              x="7.05025"
                                              y="15.5356"
                                              width="12"
                                              height="2"
                                              rx="1"
                                              transform="rotate(-45 7.05025 15.5356)"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="8.46447"
                                              y="7.05029"
                                              width="12"
                                              height="2"
                                              rx="1"
                                              transform="rotate(45 8.46447 7.05029)"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                      </a>
                                    </Small>
                                  </FlexBox>
                                );
                              })}
                            </Box>
                          </Box>
                        </div>
                      </Box>
                    </Popover>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="">
            {/* <Input
              type="textarea"
              rows={5}
              className="form-control form-control-solid"
              name="message"
              value={value.message}
              disabled={isEditing && !value.enabled}
              onChange={({ target }) => {
                let _value = { ...value };
                _value.message = target.value;
                setValue(_value);
                onChange(_value);
              }} 
            ></Input> */}
            <Box
              color="text.secondary"
              sx={{
                backgroundColor: "#f5f8fa",
                borderRadius: 2,
              }}
              className={`w-100 messages-input-box ${
                !valid ? " border border-danger" : ""
              } position-relative mb-0 text-gray-800`}
            >
              <Autosuggest
                inputProps={{
                  value: value.message,
                  placeholder: "Pick a suggestion or type your own...",
                  onChange: (event, { newValue, method }) => {
                    let _value = { ...value };
                    _value.message = newValue;
                    setValue(_value);
                    if (!isEditing) {
                      onChange(_value);
                    }
                  },
                  onBlur: (e) => {
                    if (isEditing) {
                      let is_valid = true;
                      if (kycMessage && !e.target.value?.includes("{OTP}")) {
                        is_valid = false;
                      }
                      if (is_valid) {
                        let _value = { ...value };
                        _value.message = e.target.value;
                        onChange(_value, true);
                      }
                    }
                  },
                }}
                shouldRenderSuggestions={(v) => v.trim().length > -1}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderInputComponent={(inputProps) => (
                  <Box className="input-textarea">
                    <textarea {...inputProps}></textarea>
                  </Box>
                )}
              />
              <Box
                sx={{
                  position: "relative",
                  textAlign: "right",
                }}
              >
                {emoji && (
                  <a
                    onClick={() => setShowEmojis(true)}
                    className={`open-emojis automation-emoji position-absolute cursor-pointer`}
                    type="button"
                  >
                    <i className="fa-regular fa-face-smile fs-2"></i>
                  </a>
                )}
                {showEmojis && (
                  <div
                    className={`emoji-picker emoji-picker-textarea position-absolute end-3 bottom--200px`}
                  >
                    <EmojiPicker
                      skinTonesDisabled={true}
                      onEmojiClick={handleEmojiClick}
                      theme={themMode}
                      height={380}
                      previewConfig={{
                        showPreview: false,
                      }}
                    />
                  </div>
                )}
              </Box>
            </Box>

            {closingMessages && (
              <>
                <FlexBox
                  mt={1}
                  sx={{ justifyContent: "space-between" }}
                  className="d-flex justify-content-between flex-wrap me-1"
                >
                  <div>
                    {/* <InfoToolTip
                      content={
                        "By default, closing messages are sent only to released trades. You may optionally send closing messages to cancelled trades to further customer engagement."
                      }
                      id={`message-${id}`}
                      style={{ width: "250px" }}
                    /> */}
                    {/* <Label id={`message-${id}`} className="ms-1"> */}
                    <Small color="text.secondary" fontSize={12}>
                      Enable for trade status :
                    </Small>
                    {/* </Label> */}
                  </div>
                  <FlexBox sx={{ alignItems: "center" }} className="d-flex">
                    {/* <Label className="d-flex align-items-center"> */}
                    {/* <Input
                      type="checkbox"
                      className="closing-message-checkbox ms-1 me-1"
                      checked
                      disabled
                    /> */}
                    {value.for_cancelled && (
                      <Checkbox
                        checked={value.for_cancelled}
                        onChange={({ target }) => {
                          let _value = { ...value };
                          _value.for_cancelled = target.checked;
                          setValue(_value);
                        }}
                      />
                    )}
                    <Small mx={1} color="text.secondary" fontSize={12}>
                      Released
                    </Small>
                    {/* </Label> */}
                    {/* <Label className="d-flex align-items-center ms-2"> */}
                    {value.for_cancelled && (
                      <Checkbox
                        checked={value.for_cancelled}
                        onChange={({ target }) => {
                          let _value = { ...value };
                          _value.for_cancelled = target.checked;
                          setValue(_value);
                        }}
                        // onBlur={() => {
                        //   onChange(value);
                        // }}
                      />
                    )}
                    {!value.for_cancelled && (
                      <Checkbox
                        checked={value.for_cancelled}
                        onChange={({ target }) => {
                          let _value = { ...value };
                          _value.for_cancelled = target.checked;
                          setValue(_value);
                        }}
                        // onBlur={() => {
                        //   onChange(value);
                        // }}
                      />
                    )}
                    {/* <Input
                      type="checkbox"
                      className="closing-message-checkbox me-1"
                      checked={value.for_cancelled}
                      onChange={({ target }) => {
                        let _value = { ...value };
                        _value.for_cancelled = target.checked;
                        setValue(_value);
                      }}
                      onBlur={() => {
                        onChange(value);
                      }}
                    /> */}
                    <Small ml={1} color="text.secondary" fontSize={12}>
                      Cancelled
                    </Small>
                    {/* </Label> */}
                  </FlexBox>
                </FlexBox>
                {/* <div className="media-btn-label mb-2">
            <small className="text-muted">By default, closing messages are sent only to released trades. You may optionally send closing messages to cancelled trades to further customer engagement.</small>
            </div> */}
              </>
            )}
            {buttonLabel && (
              <div className="media-btn-label mb-2">
                <small className="text-muted">
                  You can optionally add an image that will be uploaded to the
                  trade chat along with message.
                </small>
              </div>
            )}
            {kycMessage && !valid && (
              <div className="mb-2">
                <small className="text-danger">
                  {`"{OTP}" acts as a placeholder for generated OTP while
                  sending SMS. So you must include "{OTP}" in the message.`}
                </small>
              </div>
            )}
            {uploadImage && (
              <div>
                <input {...getInputProps()} />

                <FlexBox
                  mt={1}
                  sx={{ alignItems: "center" }}
                  className="d-flex upload-image-wrap"
                >
                  <ATooltip
                    content={"Select a file or drag & drop to above box"}
                    id={`upload-btn-${id}`}
                    delayShow={1000}
                  >
                    <a
                      id={`upload-btn-${id}`}
                      className="btn btn-sm btn-link p-0 text-gray-800"
                      type="button"
                      onClick={open}
                    >
                      {!isUploading ? (
                        <>
                          <Box my={1} mr={1}>
                            <i className="fa-solid fa-plus"></i> {""}
                            <Small color="text.secondary">Add Image</Small>
                          </Box>
                        </>
                      ) : (
                        <span className="indicator-progress d-block">
                          <span className="spinner-border spinner-border-sm align-middle text-primary"></span>
                        </span>
                      )}
                    </a>
                  </ATooltip>

                  {file && (
                    <>
                      <div
                        style={{ pointerEvents: "auto" }}
                        className="d-flex text-center position-relative added-image"
                      >
                        <a
                          className="symbol symbol-30px cursor-pointer"
                          onClick={() => {
                            setToggler(!toggler);
                          }}
                        >
                          {extension && extension !== "pdf" ? (
                            <img
                              src={`${COMMON_API_BASE_URL}/${file}`}
                              width={"50px"}
                            />
                          ) : (
                            <img
                              src="/media/svg/files/pdf.svg"
                              width={"50px"}
                            />
                          )}
                        </a>
                        {/* <a
                          // onClick={handleRemoveImage}
                          className="remove-image position-absolute bg-danger cursor-pointer"
                        >
                          <i className="fa-solid fa-xmark text-light"></i>
                        </a> */}
                        <span>
                          <a
                            className="cursor-pointer remove-card text-hover-danger"
                            style={{ marginBottom: "30px" }}
                          >
                            <CloseIcon
                              color="primary"
                              sx={{ fontSize: 16, marginBottom: "20px" }}
                            />
                          </a>
                        </span>

                        <FsLightbox
                          toggler={toggler}
                          sources={[`${COMMON_API_BASE_URL}/${file}`]}
                          thumbs={[`${COMMON_API_BASE_URL}/${file}`]}
                          thumbsIcons={[
                            <svg
                              key="0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="28px"
                              height="28px"
                              viewBox="00 430.118 430.118"
                            >
                              <path d="M367.243,28.754c-59.795-1.951-100.259,31.591-121.447,100.664c10.912-4.494,21.516-6.762,31.858-6.762c21.804,0,31.455,12.237,28.879,36.776c-1.278,14.86-10.911,36.482-28.879,64.858c-18.039,28.423-31.513,42.61-40.464,42.61c-11.621,0-22.199-21.958-31.857-65.82c-3.239-12.918-9.031-45.812-17.324-98.765c-7.775-49.046-28.32-71.962-61.727-68.741C112.15,34.873,90.98,47.815,62.726,72.308C42.113,91.032,21.228,109.761,0,128.471l20.225,26.112c19.303-13.562,30.595-20.311,33.731-20.311c14.802,0,28.625,23.219,41.488,69.651c11.53,42.644,23.158,85.23,34.744,127.812c17.256,46.466,38.529,69.708,63.552,69.708c40.473,0,90.028-38.065,148.469-114.223c56.537-72.909,85.725-130.352,87.694-172.341C432.498,58.764,411.613,30.028,367.243,28.754z" />
                            </svg>,
                          ]}
                          showThumbsOnMount={true}
                          svg={{
                            toolbarButtons: {
                              thumbs: {
                                viewBox: "0 0 278 278",
                                width: "17px",
                                height: "17px",
                                d: "M0 0 H119.054 V119.054 H0 V0 z M158.946 0 H278 V119.054 H158.946 V0 z M158.946 158.946 H278 V278 H158.946 V158.946 z M0 158.946 H119.054 V278 H0 V158.946 z",
                                title: "Preview",
                              },
                              zoomIn: {
                                width: "20px",
                              },
                              zoomOut: {
                                height: "20px",
                              },
                              slideshow: {
                                start: {
                                  width: "20px",
                                },
                                pause: {
                                  viewBox: "0 0 31 31",
                                },
                              },
                              fullscreen: {
                                enter: {
                                  title: "Fullscreen — Show",
                                },
                                exit: {
                                  title: "Fullscreen — Exit",
                                },
                              },
                              close: {
                                height: "32px",
                              },
                            },
                            slideButtons: {
                              previous: {
                                width: "40px",
                              },
                              next: {
                                title: "Next",
                              },
                            },
                          }}
                        />
                      </div>
                    </>
                  )}
                  {/* <a
                    // onClick={handleRemoveImage}
                    className="remove-image position-absolute bg-danger cursor-pointer"
                    style={{ marginBottom: "20px" }}
                  >
                    <i className="fa-solid fa-xmark text-light"></i>
                  </a> */}
                  {/*<a
                        onClick={() => {
                          setShowLightBox(true);
                        }}
                        className="symbol symbol-30px cursor-pointer"
                      >
                        <img src={`${COMMON_API_BASE_URL}/${file}`} alt="" />
                      </a>
                       <Lightbox
                        open={showLightBox}
                        close={() => setShowLightBox(false)}
                        plugins={[Thumbnails, Zoom, Slideshow, Fullscreen]}
                        slides={[{ src: `${COMMON_API_BASE_URL}/${file}` }]}
                        thumbnails={{ position: "bottom" }}
                      /> 
                       {showLightBox && (
                        <Lightbox
                          mainSrc={`${COMMON_API_BASE_URL}/${file}`}
                          onCloseRequest={() => {
                            setShowLightBox(false);
                          }}
                        />
                        
                      )} */}
                </FlexBox>

                {(isDragActive || (isUploading && !dialogOpen)) && (
                  // <Tween to={{ top: 0, ease: "linear" }} duration={0.01}>
                  <div
                    id="file-dropzone"
                    className={
                      isDragActive || (isUploading && !dialogOpen)
                        ? "active"
                        : "hidden"
                    }
                    //className="hidden"
                  >
                    <div>
                      {isUploading ? (
                        <div>
                          <span className="indicator-progress d-block">
                            <span className="spinner-border spinner-border-sm align-middle ms-2 w-40px h-40px text-primary"></span>
                          </span>
                        </div>
                      ) : (
                        <div>
                          <div className="fw-bold text-muted fs-4 text-center">
                            Drop files here
                          </div>
                          <small className="text-muted text-center">
                            (Only JPG, PNG, JPEG and PDF are accepted)
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  // </Tween>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

Textarea.defaultProps = {
  isEditing: false,
  enabled: true,
  message: "",
  payment_methods: [],
  uploadImage: false,
  buttonLabel: false,
  closingMessages: false,
  emoji: true,
  kycMessage: false,
};

export default Textarea;
