import React, { useEffect, useState } from "react";
// import TradesService from "../../services/TradesService";
import FlexBox from "components/flexbox/FlexBox";
import { IconButton, styled, Button, Box } from "@mui/material";

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));

function TrustBlockPartner({ stats, trade }) {
  const [isLoadingTrust, setLoadingTrust] = useState(false);
  const [isLoadingBlocked, setLoadingBlocked] = useState(false);

  const doTrustPartner = async () => {
    setLoadingTrust(true);
    // await TradesService.trustPartner(trade._id);
  };

  const doUnTrustPartner = async () => {
    setLoadingTrust(true);
    // await TradesService.unTrustPartner(trade._id);
  };

  const doBlockPartner = async () => {
    setLoadingBlocked(true);
    // await TradesService.blockPartner(trade._id);
  };

  const doUnBlockPartner = async () => {
    setLoadingBlocked(true);
    // await TradesService.unBlockPartner(trade._id);
  };

  const handleTrustButtonClick = () => {
    if (stats.is_trusted) {
      doUnTrustPartner();
    } else {
      doTrustPartner();
    }
  };

  const handleBlockButtonClick = () => {
    if (stats.is_blocked) {
      doUnBlockPartner();
    } else {
      doBlockPartner();
    }
  };

  useEffect(() => {
    setLoadingBlocked(false);
  }, [stats?.is_blocked]);

  useEffect(() => {
    setLoadingTrust(false);
  }, [stats?.is_trusted]);

  return (
    <FlexBox
      mt={1}
      className="d-flex justify-content-space-around trust-partner mt-2"
    >
      <Box>
        <StyledButton
          disabled={true}
          // onClick={() => onClick(trade.trade_hash)}
          variant="outlined"
          color="primary"
          size="small"
        >
          {!stats?.is_trusted ? "Trust" : "Untrust"}
        </StyledButton>
      </Box>
      <Box>
        <StyledButton
          disabled={true}
          // onClick={() => onClick(trade.trade_hash)}
          variant="outlined"
          color="primary"
          size="small"
        >
          {!stats?.is_blocked ? "Block" : "Unblock"}
        </StyledButton>
      </Box>
    </FlexBox>
  );
}

export default TrustBlockPartner;
