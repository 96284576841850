import axios from "../utils/axios";

const AbandonedUserService = {};

AbandonedUserService.getList = function (page, limit, search) {
  return axios({
    url: `/abandoned-user?page=${page}&limit=${limit}&search=${search}`,
    method: "GET",
  });
};
AbandonedUserService.deleteAbandonedUser = function (id) {
  return axios({
    url: `/abandoned-user/${id}`,
    method: "DELETE",
  });
};
export default AbandonedUserService;
