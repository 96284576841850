import { Key } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const PasswordButton = (props) => {
  return (
    <IconButton {...props}>
      <Key
        sx={{
          fontSize: 20,
          // color: "text.secondary",
        }}
      />
    </IconButton>
  );
};

export default PasswordButton;
