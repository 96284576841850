import { Box } from "@mui/material";
import { Paragraph, Span } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import CountryFlag from "components/shared/CountryFlag";
import UserSymbol from "components/shared/UserSymbol";
import React from "react";

function UserProfile({ user }) {
  console.log("user", user);
  return (
    <FlexBox>
      <Box mt={1}>
        <UserSymbol user={user} size={30} isVa={true} />
      </Box>
      <Box ml={1}>
        <FlexBox sx={{ alignItems: "center" }}>
          <Paragraph fontSize={13} color="text.secondary" fontWeight={"bold"}>
            {" "}
            {user.username ? (
              user.username
            ) : (
              <>
                {user.first_name} {user.last_name}
              </>
            )}
          </Paragraph>
          <Span ml={1}>
            <CountryFlag code={user.country} width={"20px"} />
          </Span>
        </FlexBox>

        <Paragraph
          fontSize={13}
          color="text.secondary"
          sx={{
            maxWidth: "250px",
            overflowWrap: "break-word",
          }}
        >
          {user.email ?? user.mobile_number}
        </Paragraph>
      </Box>
    </FlexBox>
  );
}

export default UserProfile;
